import { IAusPostalCodeData, QuestionType } from "../interfaceTypes";

export const AustraliaLucidQuestions = [
  {
    questionId: "113",
    question: "What is your ethnicity?",
    displayQuestion: "What is your ethnicity?",
    type: QuestionType.SELECT,
    options: [{
      "label": "White",
      "value": "White"
    }, {
      "label": "Black or African",
      "value": "Black or African"
    }, {
      "label": "American Indian or Alaska Native",
      "value": "American Indian or Alaska Native"
    }, {
      "label": "Asian",
      "value": "Asian"
    }, {
      "label": "Native Hawaiian and Other Pacific Islander",
      "value": "Native Hawaiian and Other Pacific Islander"
    }, {
      "label": "Latino/Hispanic",
      "value": "Latino/Hispanic"
    }, {
      "label": "Other",
      "value": "Other"
    }]
  },

  {
    "questionId": "2189",
    "question": "What is your current employment status?",
    "displayQuestion": "What is your current employment status?",
    "options": [{
      "label": "Employed Full-Time (35 Hours or more each week)",
      "value": "Employed Full-Time (35 Hours or more each week)"
    }, {
      "label": "Part-Time (34 Hours or less search week)",
      "value": "Part-Time (34 Hours or less search week)"
    }, {
      "label": "Self-Employed",
      "value": "Self-Employed"
    }, {
      "label": "Retired",
      "value": "Retired"
    }, {
      "label": "Student",
      "value": "Student"
    }, {
      "label": "Unemployed",
      "value": "Unemployed"
    }, {
      "label": "Homemaker",
      "value": "Homemaker"
    }],

    type: QuestionType.SELECT,
  },

  {
    "questionId": "48741",
    "question": "What is your level of education?",
    "displayQuestion": "What is your level of education?",
    "options": [{
      "label": "Some high school, no diploma",
      "value": "Some high school, no diploma"
    }, {
      "label": "High school graduate, diploma or the equivalent (for example: GED)",
      "value": "High school graduate, diploma or the equivalent (for example: GED)"
    }, {
      "label": "Some college credit, no degree",
      "value": "Some college credit, no degree"
    }, {
      "label": "Trade/technical/vocational training",
      "value": "Trade/technical/vocational training"
    }, {
      "label": "Associate degree",
      "value": "Associate degree"
    }, {
      "label": "Bachelor's degree",
      "value": "Bachelor's degree"
    }, {
      "label": "Master's degree",
      "value": "Master's degree"
    }, {
      "label": "Professional degree",
      "value": "Professional degree"
    }, {
      "label": "Doctorate degree",
      "value": "Doctorate degree"
    }],
    "type": QuestionType.SELECT,
  }, {
    "questionId": "638",
    "question": "In your household, are you the person who makes most of the daily purchasing decisions?",
    "displayQuestion": "In your household, are you the person who makes most of the daily purchasing decisions?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "Yes",
        "value": "1"
      },
      {
        "label": "No",
        "value": "2"
      },
      {
        "label": "Share decisions equally",
        "value": "3"
      }
    ]
  },
  {
    "questionId": "640",
    "question": "How do you identify?",
    "displayQuestion": "How do you identify?",
    "type": QuestionType.RADIO,
    "options": [
      { label: "Heterosexual", value: "1" },
      { label: "Lesbian/Gay", value: "2" },
      { label: "Bisexual/Pansexual", value: "3" },
      { label: "Asexual", value: "4" },
      { label: "Other", value: "5" },
      { label: "Unsure", value: "6" },
      { label: "Prefer not to answer", value: "-3105" },
    ]
  },
  {
    "questionId": "641",
    "question": "How many people live in your household including yourself?",
    "displayQuestion": "How many people live in your household including yourself?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "1 person",
        "value": "1"
      },
      {
        "label": "2 persons",
        "value": "2"
      },
      {
        "label": "3 persons",
        "value": "3"
      },
      {
        "label": "4 persons",
        "value": "4"
      },
      {
        "label": "5 persons",
        "value": "5"
      },
      {
        "label": "More than 5 persons",
        "value": "6"
      },
      {
        "label": "Prefer not to answer",
        "value": "7"
      }
    ]
  },
  {
    "questionId": "642",
    "question": "What best describes your current household?",
    "displayQuestion": "What best describes your current household?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "Rented apartment",
        "value": "1"
      },
      {
        "label": "Owned apartment",
        "value": "2"
      },
      {
        "label": "Rented house",
        "value": "3"
      },
      {
        "label": "Owned house",
        "value": "4"
      },
      {
        "label": "Farm",
        "value": "5"
      },
      {
        "label": "University Residence",
        "value": "6"
      },
      {
        "label": "Living with parents",
        "value": "7"
      },
      {
        "label": "Other",
        "value": "8"
      }
    ]
  },
  {
    "questionId": "643",
    "question": "Do you or does anyone in your household work in any of the following industries?",
    "displayQuestion": "Do you or does anyone in your household work in any of the following industries?",
    "type": QuestionType.MULTISELECT,
    "options": [
      {
        "label": "Accounting",
        "value": "1"
      },
      {
        "label": "Advertising",
        "value": "2"
      },
      {
        "label": "Agriculture/Fishing",
        "value": "3"
      },
      {
        "label": "Architecture",
        "value": "4"
      },
      {
        "label": "Automotive",
        "value": "5"
      },
      {
        "label": "Aviation",
        "value": "6"
      },
      {
        "label": "Banking/Financial",
        "value": "7"
      },
      {
        "label": "Bio-Tech",
        "value": "8"
      },
      {
        "label": "Brokerage",
        "value": "9"
      },
      {
        "label": "Carpenting/Electrical installations/VVS",
        "value": "10"
      },
      {
        "label": "Chemicals/Plastics/Rubber",
        "value": "11"
      },
      {
        "label": "Communications/Information",
        "value": "12"
      },
      {
        "label": "Computer Hardware",
        "value": "13"
      },
      {
        "label": "Computer Reseller (software/hardware)",
        "value": "14"
      },
      {
        "label": "Computer Software",
        "value": "15"
      },
      {
        "label": "Construction",
        "value": "16"
      },
      {
        "label": "Consulting",
        "value": "17"
      },
      {
        "label": "Consumer Electronics",
        "value": "18"
      },
      {
        "label": "Consumer Packaged Goods",
        "value": "19"
      },
      {
        "label": "Education",
        "value": "20"
      },
      {
        "label": "Energy/Utilities/Oil and Gas",
        "value": "21"
      },
      {
        "label": "Engineering",
        "value": "22"
      },
      {
        "label": "Environmental Services",
        "value": "23"
      },
      {
        "label": "Fashion/Apparel",
        "value": "24"
      },
      {
        "label": "Food/Beverage",
        "value": "25"
      },
      {
        "label": "Government/Public Sector",
        "value": "26"
      },
      {
        "label": "Healthcare",
        "value": "27"
      },
      {
        "label": "Hospitality/Tourism",
        "value": "28"
      },
      {
        "label": "Human Resources",
        "value": "29"
      },
      {
        "label": "Information Technology/IT",
        "value": "30"
      },
      {
        "label": "Insurance",
        "value": "31"
      },
      {
        "label": "Internet",
        "value": "32"
      },
      {
        "label": "Legal/Law",
        "value": "33"
      },
      {
        "label": "Manufacturing",
        "value": "34"
      },
      {
        "label": "Market Research",
        "value": "35"
      },
      {
        "label": "Marketing/Sales",
        "value": "36"
      },
      {
        "label": "Media/Entertainment",
        "value": "37"
      },
      {
        "label": "Military",
        "value": "38"
      },
      {
        "label": "Non Profit/Social services",
        "value": "39"
      },
      {
        "label": "Personal Services",
        "value": "40"
      },
      {
        "label": "Pharmaceuticals",
        "value": "41"
      },
      {
        "label": "Printing Publishing",
        "value": "42"
      },
      {
        "label": "Public Relations",
        "value": "43"
      },
      {
        "label": "Real Estate/Property",
        "value": "44"
      },
      {
        "label": "Retail/Wholesale trade",
        "value": "45"
      },
      {
        "label": "Security",
        "value": "46"
      },
      {
        "label": "Shipping/Distribution",
        "value": "47"
      },
      {
        "label": "Telecommunications",
        "value": "48"
      },
      {
        "label": "Transportation",
        "value": "49"
      },
      {
        "label": "Other",
        "value": "50"
      },
      {
        "label": "I don't work",
        "value": "51"
      }
    ]
  },
  {
    "questionId": "646",
    "question": "Which department do you primarily work within at your organization?",
    "displayQuestion": "Which department do you primarily work within at your organization?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "Administration/General Staff",
        "value": "1"
      },
      {
        "label": "Customer Service/Client Service",
        "value": "2"
      },
      {
        "label": "Executive Leadership",
        "value": "3"
      },
      {
        "label": "Finance/Accounting",
        "value": "4"
      },
      {
        "label": "Human Resources",
        "value": "5"
      },
      {
        "label": "Legal/Law",
        "value": "6"
      },
      {
        "label": "Marketing",
        "value": "7"
      },
      {
        "label": "Operations",
        "value": "8"
      },
      {
        "label": "Procurement",
        "value": "9"
      },
      {
        "label": "Sales/Business Development",
        "value": "10"
      },
      {
        "label": "Technology Development Hardware (not only IT)",
        "value": "11"
      },
      {
        "label": "Technology Development Software (not only IT)",
        "value": "12"
      },
      {
        "label": "Technology Implementation",
        "value": "13"
      },
      {
        "label": "Other",
        "value": "14"
      },
      {
        "label": "I don't work",
        "value": "15"
      }
    ]
  },
  {
    "questionId": "1249",
    "question": "Please indicate the age and gender of your child or children:",
    "displayQuestion": "Please indicate the age and gender of your child or children:",
    "type": QuestionType.MULTISELECT,
    "options": [
      {
        "label": "Boy under age 1",
        "value": "1"
      },
      {
        "label": "Girl under age 1",
        "value": "2"
      },
      {
        "label": "Boy age 1",
        "value": "3"
      },
      {
        "label": "Girl age 1",
        "value": "4"
      },
      {
        "label": "Boy age 2",
        "value": "5"
      },
      {
        "label": "Girl age 2",
        "value": "6"
      },
      {
        "label": "Boy age 3",
        "value": "7"
      },
      {
        "label": "Girl age 3",
        "value": "8"
      },
      {
        "label": "Boy age 4",
        "value": "9"
      },
      {
        "label": "Girl age 4",
        "value": "10"
      },
      {
        "label": "Boy age 5",
        "value": "11"
      },
      {
        "label": "Girl age 5",
        "value": "12"
      },
      {
        "label": "Boy age 6",
        "value": "13"
      },
      {
        "label": "Girl age 6",
        "value": "14"
      },
      {
        "label": "Boy age 7",
        "value": "15"
      },
      {
        "label": "Girl age 7",
        "value": "16"
      },
      {
        "label": "Boy age 8",
        "value": "17"
      },
      {
        "label": "Girl age 8",
        "value": "18"
      },
      {
        "label": "Boy age 9",
        "value": "19"
      },
      {
        "label": "Girl age 9",
        "value": "20"
      },
      {
        "label": "Boy age 10",
        "value": "21"
      },
      {
        "label": "Girl age 10",
        "value": "22"
      },
      {
        "label": "Boy age 11",
        "value": "23"
      },
      {
        "label": "Girl age 11",
        "value": "24"
      },
      {
        "label": "Boy age 12",
        "value": "25"
      },
      {
        "label": "Girl age 12",
        "value": "26"
      },
      {
        "label": "Male teen age 13",
        "value": "27"
      },
      {
        "label": "Female teen age 13",
        "value": "28"
      },
      {
        "label": "Male teen age 14",
        "value": "29"
      },
      {
        "label": "Female teen age 14",
        "value": "30"
      },
      {
        "label": "Male teen age 15",
        "value": "31"
      },
      {
        "label": "Female teen age 15",
        "value": "32"
      },
      {
        "label": "Male teen age 16",
        "value": "33"
      },
      {
        "label": "Female teen age 16",
        "value": "34"
      },
      {
        "label": "Male teen age 17",
        "value": "35"
      },
      {
        "label": "Female teen age 17",
        "value": "36"
      },
      {
        "label": "None of the above",
        "value": "-3105"
      }
    ]
  },
  {
    "questionId": "3546",
    "question": "Please choose which departments/products you have influence or decision making authority over regarding spending/purchasing",
    "displayQuestion": "Please choose which departments/products you have influence or decision making authority over regarding spending/purchasing",
    "type": QuestionType.MULTISELECT,
    "options": [
      {
        "label": "IT Hardware",
        "value": "1"
      },
      {
        "label": "IT Software",
        "value": "2"
      },
      {
        "label": "Printers and copiers",
        "value": "3"
      },
      {
        "label": "Financial Department",
        "value": "4"
      },
      {
        "label": "Human Resources",
        "value": "5"
      },
      {
        "label": "Office supplies",
        "value": "6"
      },
      {
        "label": "Corporate travel",
        "value": "7"
      },
      {
        "label": "Telecommunications",
        "value": "8"
      },
      {
        "label": "Sales",
        "value": "9"
      },
      {
        "label": "Shipping",
        "value": "10"
      },
      {
        "label": "Operations",
        "value": "11"
      },
      {
        "label": "Legal services",
        "value": "12"
      },
      {
        "label": "Marketing/Advertising",
        "value": "13"
      },
      {
        "label": "Security",
        "value": "14"
      },
      {
        "label": "Food services",
        "value": "15"
      },
      {
        "label": "Auto leasing/purchasing",
        "value": "16"
      },
      {
        "label": "Procurement",
        "value": "19"
      },
      {
        "label": "Other",
        "value": "17"
      },
      {
        "label": "I don’t have influence or decision making authority",
        "value": "18"
      }
    ]
  },
  {
    "questionId": "3708",
    "question": "How many children do you have under the age of 18?",
    "displayQuestion": "How many children do you have under the age of 18?",
    "type": QuestionType.NUMERICFIELD,
    "options": []
  },
  {
    "questionId": "7064",
    "question": "Please choose the options that best describe your household",
    "displayQuestion": "Please choose the options that best describe your household",
    "type": QuestionType.MULTISELECT,
    "options": [
      {
        "label": "I am pregnant/expecting a child within the next 9 months",
        "value": "1"
      },
      {
        "label": "I have one or more children under the age of 18 living in my household",
        "value": "2"
      },
      {
        "label": "I have one or more children aged 18 or older living in my household",
        "value": "3"
      },
      {
        "label": "I have no children living in my household and I am not pregnant/expecting a child within the next 9 months",
        "value": "4"
      }
    ]
  },
  {
    "questionId": "15297",
    "question": "What is your job title, level or responsibility?",
    "displayQuestion": "What is your job title, level or responsibility?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "C-Level (e.g. CEO, CFO), Owner, Partner, President",
        "value": "1"
      },
      {
        "label": "Vice President (EVP, SVP, AVP, VP)",
        "value": "2"
      },
      {
        "label": "Director (Group Director, Sr. Director, Director)",
        "value": "3"
      },
      {
        "label": "Manager (Group Manager, Sr. Manager, Manager, Program Manager)",
        "value": "4"
      },
      {
        "label": "Analyst",
        "value": "5"
      },
      {
        "label": "Assistant or Associate",
        "value": "6"
      },
      {
        "label": "Administrative (Clerical or Support Staff)",
        "value": "7"
      },
      {
        "label": "Consultant",
        "value": "8"
      },
      {
        "label": "Intern",
        "value": "9"
      },
      {
        "label": "Volunteer",
        "value": "10"
      },
      {
        "label": "None of the above",
        "value": "11"
      }
    ]
  },
  // {
  //   "questionId": "48741",
  //   "question": "What is the highest level of education you have completed?",
  //   "displayQuestion": "What is the highest level of education you have completed?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Less than year 10",
  //       "value": "1"
  //     },
  //     {
  //       "label": "Year 10 graduate",
  //       "value": "2"
  //     },
  //     {
  //       "label": "High school graduate (Year 12)",
  //       "value": "3"
  //     },
  //     {
  //       "label": "HTAFE certificate",
  //       "value": "4"
  //     },
  //     {
  //       "label": "TAFE diploma",
  //       "value": "5"
  //     },
  //     {
  //       "label": "Bachelor's degree",
  //       "value": "6"
  //     },
  //     {
  //       "label": "Master's degree",
  //       "value": "7"
  //     },
  //     {
  //       "label": "Doctorate degree",
  //       "value": "8"
  //     },
  //     {
  //       "label": "None of the above",
  //       "value": "-3105"
  //     },
  //   ]
  // },
  {
    "questionId": "61076",
    "question": "What is your yearly household income?",
    "displayQuestion": "What is your yearly household income?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "Less than $5,000",
        "value": "1"
      },
      {
        "label": "$5,000 - $9,999",
        "value": "2"
      },
      {
        "label": "$10,000 - $14,999",
        "value": "3"
      },
      {
        "label": "$15,000 - $19,999",
        "value": "4"
      },
      {
        "label": "$20,000 - $24,999",
        "value": "5"
      },
      {
        "label": "$25,000 - $29,999",
        "value": "6"
      },
      {
        "label": "$30,000 - $34,999",
        "value": "7"
      },
      {
        "label": "$35,000 - $39,999",
        "value": "8"
      },
      {
        "label": "$40,000 - $44,999",
        "value": "9"
      },
      {
        "label": "$45,000 - $49,999",
        "value": "10"
      },
      {
        "label": "$50,000 - $54,999",
        "value": "11"
      },
      {
        "label": "$55,000 - $59,999",
        "value": "12"
      },
      {
        "label": "$60,000 - $69,999",
        "value": "13"
      },
      {
        "label": "$70,000 - $79,999",
        "value": "14"
      },
      {
        "label": "$80,000 - $89,999",
        "value": "15"
      },
      {
        "label": "$90,000 - $99,999",
        "value": "16"
      },
      {
        "label": "$100,000 - $149,999",
        "value": "17"
      },
      {
        "label": "$150,000 - $199,999",
        "value": "18"
      },
      {
        "label": "$200,000 - $249,999",
        "value": "19"
      },
      {
        "label": "$250,000 - $299,999",
        "value": "20"
      },
      {
        "label": "$300,000 - $349,999",
        "value": "21"
      },
      {
        "label": "$350,000 - $399,999",
        "value": "22"
      },
      {
        "label": "$400,000 - $500,000",
        "value": "23"
      },
      {
        "label": "More than $500,000",
        "value": "24"
      },
      {
        "label": "Prefer not to answer",
        "value": "-3105"
      }
    ]
  },
  // {
  //   "questionId": "79330",
  //   "question": "What area or city do you live in?",
  //   "displayQuestion": "What area or city do you live in?",
  //   "type": QuestionType.TEXTFIELD,
  //   "options": []
  // },
  {
    "questionId": "79355",
    "question": "What is your postal code?",
    "displayQuestion": "What is your postal code?",
    "type": QuestionType.SEARCHINPUT,
    "options": []
  },
  // {
  //   "questionId": "79397",
  //   "question": "What state do you live in?",
  //   "displayQuestion": "What state do you live in?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Australian Capital Territory",
  //       "value": "1"
  //     },
  //     {
  //       "label": "Christmas Island",
  //       "value": "2"
  //     },
  //     {
  //       "label": "Cocos (Keeling) Islands",
  //       "value": "3"
  //     },
  //     {
  //       "label": "Heard Island and McDonald Islands",
  //       "value": "4"
  //     },
  //     {
  //       "label": "New South Wales",
  //       "value": "5"
  //     },
  //     {
  //       "label": "Northern Territory",
  //       "value": "6"F
  //     },
  //     {
  //       "label": "Queensland",
  //       "value": "7"
  //     },
  //     {
  //       "label": "South Australia",
  //       "value": "8"
  //     },
  //     {
  //       "label": "Tasmania",
  //       "value": "9"
  //     },
  //     {
  //       "label": "Victoria",
  //       "value": "10"
  //     },
  //     {
  //       "label": "Western Australia",
  //       "value": "11"
  //     }
  //   ]
  // },
  {
    "questionId": "105013",
    "question": "Which of the following best describes the area you live in?",
    "displayQuestion": "Which of the following best describes the area you live in?",
    "type": QuestionType.SELECT,
    "options": [
      {
        "label": "Urban – Densely populated, city or large town",
        "value": "1"
      },
      {
        "label": "Suburban – Mainly residential, bordering a city or large town",
        "value": "2"
      },
      {
        "label": "Rural – Sparsely populated, small town or village",
        "value": "3"
      }
    ]
  },
  // {
  //   "questionId": "137510",
  //   "question": "Are you?",
  //   "displayQuestion": "Are you?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Male",
  //       "value": "1"
  //     },
  //     {
  //       "label": "Female",
  //       "value": "2"
  //     },
  //     {
  //       "label": "Another gender",
  //       "value": "3"
  //     },
  //     {
  //       "label": "I Prefer not to answer",
  //       "value": "4"
  //     }
  //   ]
  // },
  // {
  //   "questionId": "29226",
  //   "type": QuestionType.SELECT,
  //   "question": "In order to qualify for this survey you may be asked to download an app or plug-in Would you like to continue?",
  //   "displayQuestion": "In order to qualify for this survey you may be asked to download an app or plug-in Would you like to continue?",
  //   "options": [
  //     { label: "Yes", value: "1" },
  //     { label: "No", value: "2" },
  //   ],
  // },
  // {
  //   "questionId": "30319",
  //   "question": "You have indicated that you have a child in the household under the age of 18 Is your child available to come to the computer and participate in a survey with your assistance?",
  //   "displayQuestion": "You have indicated that you have a child in the household under the age of 18 Is your child available to come to the computer and participate in a survey with your assistance?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Yes",
  //       "value": "1"
  //     },
  //     {
  //       "label": "No",
  //       "value": "2"
  //     },
  //   ]
  // },
  // {
  //   "questionId": "30321",
  //   "question": "Thank you for agreeing to participate At this point please pass the computer over to your child and have them answer the following questions from his or her perspective The next questions will be for your teen",
  //   "displayQuestion": "Thank you for agreeing to participate At this point please pass the computer over to your child and have them answer the following questions from his or her perspective The next questions will be for your teen",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Continue",
  //       "value": "1"
  //     },
  //     {
  //       "label": "I've changed my mind - my teen cannot participate",
  //       "value": "2"
  //     },
  //   ]
  // },
  // {
  //   "questionId": "30226",
  //   "question": "You have indicated that you have a teenage child in the household between the ages of 13 and 17 Is your teenage child available to come to the computer and participate in a survey with your assistance?",
  //   "displayQuestion": "You have indicated that you have a teenage child in the household between the ages of 13 and 17 Is your teenage child available to come to the computer and participate in a survey with your assistance?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "Yes",
  //       "value": "1"
  //     },
  //     {
  //       "label": "No",
  //       "value": "2"
  //     },
  //   ]
  // },
  // {
  //   "questionId": "43501",
  //   "question": "Do you have a webcam and are you willing to use it for an online research opportunity?",
  //   "displayQuestion": "Do you have a webcam and are you willing to use it for an online research opportunity?",
  //   "type": QuestionType.SELECT,
  //   "options": [
  //     {
  //       "label": "I have a webcam and I am willing to use it for an online research opportunity.",
  //       "value": "1"
  //     },
  //     {
  //       "label": "I have a webcam but I am not willing to use it for an online research opportunity.",
  //       "value": "2"
  //     },
  //     {
  //       "label": "I do not have a webcam.",
  //       "value": "3"
  //     },
  //   ]
  // },
  // {
  //   "questionId": "P6Q7",
  //   "type": QuestionType.MULTISELECT,
  //   "question": "Please let us know your survey and research preferences",
  //   "displayQuestion": "Please let us know your survey and research preferences",
  //   "options": [
  //     { "label": "Patient & Caregiver Surveys & Interviews", "value": "Patient & Caregiver Surveys & Interviews" },
  //     { "label": "Online Interviews", "value": "Online Interviews" },
  //     { "label": "Business Surveys & Interviews", "value": "Business Surveys & Interviews" },
  //     { "label": "Consumer Surveys", "value": "Consumer Surveys" }
  //   ],
  // }
]

export const AustraliaInitialValue = {
  // "What is your age?": "",
  // "What is your gender?": "",
  "What is your ethnicity?": "",
  "What is your current employment status?": "",
  "What is your level of education?": "",
  "In your household, are you the person who makes most of the daily purchasing decisions?": "",
  "How do you identify?": "",
  "How many people live in your household including yourself?": "",
  "What best describes your current household?": "",
  "Do you or does anyone in your household work in any of the following industries?": "",
  "Which department do you primarily work within at your organization?": "",
  "Please indicate the age and gender of your child or children:": [],
  "Please choose which departments/products you have influence or decision making authority over regarding spending/purchasing": [],
  "How many children do you have under the age of 18?": "",
  "Please choose the options that best describe your household": [],
  "What is your job title, level or responsibility?": "",
  "What is your yearly household income?": "",
  "Which of the following best describes the area you live in?": "",
  "What is your postal code?": "",
  // "In order to qualify for this survey you may be asked to download an app or plug-in Would you like to continue?": "",
  // "You have indicated that you have a child in the household under the age of 18 Is your child available to come to the computer and participate in a survey with your assistance?": "",
  // "Thank you for agreeing to participate At this point please pass the computer over to your child and have them answer the following questions from his or her perspective The next questions will be for your teen": "",
  // "You have indicated that you have a teenage child in the household between the ages of 13 and 17 Is your teenage child available to come to the computer and participate in a survey with your assistance?": "",
  // "Do you have a webcam and are you willing to use it for an online research opportunity?": "",
  // "What is the highest level of education you have completed?": "",
}


export const AustraliaPostalCodeData: IAusPostalCodeData = {
  "0200": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "0221": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "0800": { state: { label: "Northern Territory", value: "6" }, area: { label: "Darwin", value: "6" } },
  "0801": { state: { label: "Northern Territory", value: "6" }, area: { label: "Darwin", value: "6" } },
  "0803": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0804": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0810": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0811": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0812": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0813": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0814": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0815": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0820": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0821": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0822": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0828": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0829": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0830": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0831": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0832": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0834": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0835": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0836": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0837": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0838": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0839": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0840": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0841": { state: { label: "Northern Territory", value: "6" }, area: { label: "Darwin", value: "6" } },
  "0845": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0846": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0847": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0850": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0851": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0852": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0853": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0854": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0860": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0861": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0862": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0870": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0871": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0872": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0873": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0874": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0875": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0880": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0881": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0885": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0886": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0906": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0907": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "0909": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "1001": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1002": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1003": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1004": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1005": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1006": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1007": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1008": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1009": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1010": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1011": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1020": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1021": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1022": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1023": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1025": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1026": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1027": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1028": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1029": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1030": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1031": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1032": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1033": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1034": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1035": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1036": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1037": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1038": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1039": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1040": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1041": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1042": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1043": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1044": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1045": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1046": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1100": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1101": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1105": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1106": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1107": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1108": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1109": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1110": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1112": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1113": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1114": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1115": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1116": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1117": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1118": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1119": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1120": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1121": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1122": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1123": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1124": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1125": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1126": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1127": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1128": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1129": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1130": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1131": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1132": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1133": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1134": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1135": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1136": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1137": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1138": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1139": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1140": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1141": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1142": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1143": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1144": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1145": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1146": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1147": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1148": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1149": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1150": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1151": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1152": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1153": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1154": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1155": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1156": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1157": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1158": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1159": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1160": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1161": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1162": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1163": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1164": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1165": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1166": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1167": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1168": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1169": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1170": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1171": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1172": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1173": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1174": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1175": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1176": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1177": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1178": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1179": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1180": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1181": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1182": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1183": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1184": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1185": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1186": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1187": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1188": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1189": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1190": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1191": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1192": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1193": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1194": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1195": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1196": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1197": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1198": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1199": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1200": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1201": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1202": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1203": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1204": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1205": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1206": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1207": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1208": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1209": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1210": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1211": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1212": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1213": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1214": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1215": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1216": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1217": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1218": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1219": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1220": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1221": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1222": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1223": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1224": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1225": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1226": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1227": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1228": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1229": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1230": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1231": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1232": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1233": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1234": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1235": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1236": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1237": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1238": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1239": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1240": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1291": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1292": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1293": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1294": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1295": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1296": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1297": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1298": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1299": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "1300": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1311": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1312": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1313": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1314": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1315": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1316": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1317": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1318": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1319": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1320": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1321": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1322": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1323": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1324": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1325": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1326": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1327": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1328": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1329": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1330": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1331": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1332": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1333": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1334": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1335": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1340": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1350": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1355": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1360": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1391": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1401": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1416": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1419": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1420": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1421": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1422": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1423": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1424": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1425": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1426": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1427": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1428": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1429": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1430": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1435": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1440": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1445": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1450": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1455": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1460": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1465": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1466": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1470": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1475": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1480": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1481": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1484": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1485": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1487": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1490": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1493": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1495": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1499": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1515": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1560": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1565": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1570": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1582": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1585": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1590": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1595": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1597": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1602": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1630": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1635": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1639": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1640": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1655": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1658": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1660": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1670": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1671": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1675": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1680": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1685": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1690": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1691": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1692": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1693": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1694": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1695": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1696": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1697": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1698": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1699": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1700": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1701": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1710": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1712": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1715": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1730": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1740": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1741": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1750": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1755": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1765": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1771": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1781": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1790": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1797": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1800": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1805": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1811": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1825": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1826": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1830": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1831": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1835": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1848": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1851": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1860": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1871": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1875": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1885": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1888": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1890": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1891": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1900": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "1902": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2000": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2001": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "2002": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2004": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2006": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "2007": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2008": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2009": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2010": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2011": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2012": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2015": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2016": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2017": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2018": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2019": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2020": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2021": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2022": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2023": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2024": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2025": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2026": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2027": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2028": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2029": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2030": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2031": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2032": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2033": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2034": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2035": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2036": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2037": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2038": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2039": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2040": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2041": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2042": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2043": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2044": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2045": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2046": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2047": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2048": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2049": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2050": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2052": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2055": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2057": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2058": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2059": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2060": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2061": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2062": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2063": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2064": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2065": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2066": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2067": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2068": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2069": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2070": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2071": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2072": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2073": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2074": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2075": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2076": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2077": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2079": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2080": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2081": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2082": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2083": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2084": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2085": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2086": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2087": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2088": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2089": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2090": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2091": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2092": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2093": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2094": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2095": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2096": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2097": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2099": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2100": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2101": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2102": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2103": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2104": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2105": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2106": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2107": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2108": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2109": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2110": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2111": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2112": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2113": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2114": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2115": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2116": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2117": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2118": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2119": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2120": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2121": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2122": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2123": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2124": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2125": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2126": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2127": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2128": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2129": { state: { label: "New South Wales", value: "5" }, area: { label: "Sydney", value: "19" } },
  "2130": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2131": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2132": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2133": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2134": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2135": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2136": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2137": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2138": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2139": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2140": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2141": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2142": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2143": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2144": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2145": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2146": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2147": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2148": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2150": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2151": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2152": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2153": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2154": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2155": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2156": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2157": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2158": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2159": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2160": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2161": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2162": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2163": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2164": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2165": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2166": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2167": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2168": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2170": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2171": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2172": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2173": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2174": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2175": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2176": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2177": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2178": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2179": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2190": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2191": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2192": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2193": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2194": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2195": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2196": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2197": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2198": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2199": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2200": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2203": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2204": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2205": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2206": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2207": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2208": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2209": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2210": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2211": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2212": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2213": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2214": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2216": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2217": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2218": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2219": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2220": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2221": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2222": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2223": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2224": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2225": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2226": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2227": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2228": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2229": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2230": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2231": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2232": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2233": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2234": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2250": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2251": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2252": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2256": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2257": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2258": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2259": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2260": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2261": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2262": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2263": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2264": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2265": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2267": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2278": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2280": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2281": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2282": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2283": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2284": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2285": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2286": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2287": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2289": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2290": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2291": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2292": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2293": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2294": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2295": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2296": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2297": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2298": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2299": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2300": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2302": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2303": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2304": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2305": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2306": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2307": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2308": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2309": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2310": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2311": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2312": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2314": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2315": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2316": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2317": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2318": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2319": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2320": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2321": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2322": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2323": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2324": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2325": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2326": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2327": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2328": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2329": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2330": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2331": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2333": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2334": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2335": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2336": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2337": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2338": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2339": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2340": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2341": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2342": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2343": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2344": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2345": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2346": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2347": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2348": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2350": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2351": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2352": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2353": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2354": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2355": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2356": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2357": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2358": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2359": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2360": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2361": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2365": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2369": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2370": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2371": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2372": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2379": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2380": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2381": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2382": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2386": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2387": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2388": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2390": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2395": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2396": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2397": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2398": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2399": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2400": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2401": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2402": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2403": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2404": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2405": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2406": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2408": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2409": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2410": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2411": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2415": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2420": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2421": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2422": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2423": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2424": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2425": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2426": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2427": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2428": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2429": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2430": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2431": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2439": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2440": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2441": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2442": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2443": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2444": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2445": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2446": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2447": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2448": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2449": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2450": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2452": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2453": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2454": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2455": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2456": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2460": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2462": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2463": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2464": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2465": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2466": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2469": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2470": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2471": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2472": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2473": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2474": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2475": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2476": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2477": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2478": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2479": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2480": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2481": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2482": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2483": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2484": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2485": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2486": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2487": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2488": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2489": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2490": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2500": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2502": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2505": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2506": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2508": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2515": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2516": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2517": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2518": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2519": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2520": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2522": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2525": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2526": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2527": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2528": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2529": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2530": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2533": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2534": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2535": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2536": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2537": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2538": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2539": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2540": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2541": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2545": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2546": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2548": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2549": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2550": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2551": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2555": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2556": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2557": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2558": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2559": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2560": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2563": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2564": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2565": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2566": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2567": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2568": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2569": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2570": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2571": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2572": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2573": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2574": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2575": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2576": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2577": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2578": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2579": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2580": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2581": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2582": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2583": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2584": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2585": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2586": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2587": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2588": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2590": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2594": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2600": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2601": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2602": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2603": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2604": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2605": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2606": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2607": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2608": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2609": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2610": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2611": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2612": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2614": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2615": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2616": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2617": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2618": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2619": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2620": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2621": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2622": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2623": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2624": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2625": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2626": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2627": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2628": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2629": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2630": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2631": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2632": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2633": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2640": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2641": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2642": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2643": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2644": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2645": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2646": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2647": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2648": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2649": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2650": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2651": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2652": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2653": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2655": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2656": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2658": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2659": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2660": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2661": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2663": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2665": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2666": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2668": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2669": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2671": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2672": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2675": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2678": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2680": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2681": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2700": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2701": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2702": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2703": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2705": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2706": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2707": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2708": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2710": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2711": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2712": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2713": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2714": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2715": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2716": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2717": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2720": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2721": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2722": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2725": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2726": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2727": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2729": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2730": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2731": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2732": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2733": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2734": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2735": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2736": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2737": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2738": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2739": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2745": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2747": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2748": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2749": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2750": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2751": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2752": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2753": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2754": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2755": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2756": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2757": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2758": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2759": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2760": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2761": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2762": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2763": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2765": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2766": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2767": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2768": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2769": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2770": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2773": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2774": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2775": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2776": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2777": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2778": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2779": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2780": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2782": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2783": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2784": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2785": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2786": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2787": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2790": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2791": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2792": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2793": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2794": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2795": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2797": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2798": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2799": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2800": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2803": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2804": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2805": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2806": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2807": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2808": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2809": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2810": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2818": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2820": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2821": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2823": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2824": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2825": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2826": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2827": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2828": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2829": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2830": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2831": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2832": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2833": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2834": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2835": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2836": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2838": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2839": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2840": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2842": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2843": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2844": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2845": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2846": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2847": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2848": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2849": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2850": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2852": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2864": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2865": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2866": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2867": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2868": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2869": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2870": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2871": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2873": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2874": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2875": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2876": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2877": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2878": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2879": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2880": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2890": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2891": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2898": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2899": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "2900": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2901": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2902": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2903": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2904": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2905": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2906": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2911": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2912": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2913": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "2914": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "3000": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3001": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3002": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3003": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3004": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3005": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3006": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3008": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3010": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3011": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3012": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3013": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3015": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3016": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3018": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3019": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3020": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3021": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3022": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3023": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3024": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3025": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3026": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3027": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3028": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3029": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3030": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3031": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3032": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3033": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3034": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3036": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3037": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3038": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3039": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3040": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3041": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3042": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3043": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3044": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3045": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3046": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3047": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3048": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3049": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3050": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3051": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3052": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3053": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3054": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3055": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3056": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3057": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3058": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3059": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3060": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3061": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3062": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3063": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3064": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3065": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3066": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3067": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3068": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3070": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3071": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3072": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3073": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3074": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3075": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3076": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3078": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3079": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3081": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3082": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3083": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3084": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3085": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3086": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3087": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3088": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3089": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3090": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3091": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3093": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3094": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3095": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3096": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3097": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3099": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3101": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3102": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3103": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3104": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3105": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3106": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3107": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3108": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3109": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3110": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3111": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3113": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3114": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3115": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3116": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3121": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3122": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3123": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3124": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3125": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3126": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3127": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3128": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3129": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3130": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3131": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3132": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3133": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3134": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3135": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3136": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3137": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3138": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3139": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3140": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3141": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3142": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3143": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3144": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3145": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3146": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3147": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3148": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3149": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3150": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3151": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3152": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3153": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3154": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3155": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3156": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3158": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3159": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3160": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3161": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3162": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3163": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3164": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3165": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3166": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3167": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3168": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3169": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3170": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3171": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3172": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3173": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3174": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3175": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3176": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3177": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3178": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3179": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3180": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3181": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3182": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3183": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3184": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3185": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3186": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3187": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3188": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3189": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3190": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3191": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3192": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3193": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3194": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3195": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3196": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3197": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3198": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3199": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3200": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3201": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3202": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3204": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3205": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "3206": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3207": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3211": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3212": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3213": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3214": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3215": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3216": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3217": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3218": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3219": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3220": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3221": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3222": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3223": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3224": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3225": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3226": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3227": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3228": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3230": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3231": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3232": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3233": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3234": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3235": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3236": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3237": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3238": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3239": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3240": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3241": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3242": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3243": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3249": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3250": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3251": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3254": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3260": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3264": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3265": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3266": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3267": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3268": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3269": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3270": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3271": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3272": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3273": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3274": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3275": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3276": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3277": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3278": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3279": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3280": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3281": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3282": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3283": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3284": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3285": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3286": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3287": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3289": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3292": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3293": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3294": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3300": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3301": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3302": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3303": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3304": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3305": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3309": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3310": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3311": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3312": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3314": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3315": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3317": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3318": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3319": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3321": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3322": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3323": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3324": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3325": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3328": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3329": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3330": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3331": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3332": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3333": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3334": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3335": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3337": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3338": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3340": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3341": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3342": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3345": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3350": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3351": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3352": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3353": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3354": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3355": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3356": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3357": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3360": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3361": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3363": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3364": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3370": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3371": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3373": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3374": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3375": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3377": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3378": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3379": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3380": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3381": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3384": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3385": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3387": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3388": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3390": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3391": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3392": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3393": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3395": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3396": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3400": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3401": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3402": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3407": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3409": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3412": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3413": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3414": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3415": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3418": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3419": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3420": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3423": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3424": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3427": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3428": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3429": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3430": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3431": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3432": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3433": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3434": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3435": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3437": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3438": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3440": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3441": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3442": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3444": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3446": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3447": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3448": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3450": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3451": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3453": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3458": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3460": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3461": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3462": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3463": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3464": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3465": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3467": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3468": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3469": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3472": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3475": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3477": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3478": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3480": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3482": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3483": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3485": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3487": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3488": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3489": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3490": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3491": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3494": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3496": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3498": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3500": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3501": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3502": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3505": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3506": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3507": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3509": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3512": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3515": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3516": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3517": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3518": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3520": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3521": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3522": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3523": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3525": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3527": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3529": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3530": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3531": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3533": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3537": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3540": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3542": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3544": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3546": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3549": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3550": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3551": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3552": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3554": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3555": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3556": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3557": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3558": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3559": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3561": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3562": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3563": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3564": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3565": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3566": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3567": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3568": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3570": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3571": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3572": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3573": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3575": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3576": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3579": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3580": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3581": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3583": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3584": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3585": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3586": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3588": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3589": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3590": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3591": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3594": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3595": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3596": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3597": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3599": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3607": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3608": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3610": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3612": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3614": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3616": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3617": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3618": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3619": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3620": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3621": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3622": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3623": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3624": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3629": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3630": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3631": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3632": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3633": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3634": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3635": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3636": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3637": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3638": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3639": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3640": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3641": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3643": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3644": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "3646": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3647": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3649": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3658": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3659": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3660": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3661": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3662": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3663": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3664": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3665": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3666": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3669": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3670": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3671": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3672": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3673": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3675": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3676": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3677": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3678": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3682": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3683": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3685": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3687": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3688": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3689": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3690": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3691": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "3694": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3695": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3697": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3698": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3699": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3700": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3701": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3704": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3705": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3707": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "3708": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3709": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3711": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3712": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3713": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3714": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3715": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3717": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3718": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3719": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3720": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3722": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3723": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3724": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3725": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3726": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3727": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3728": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3730": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3732": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3733": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3735": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3736": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3737": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3738": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3739": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3740": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3741": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3744": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3746": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3747": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3749": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3750": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3751": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3752": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3753": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3754": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3755": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3756": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3757": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3758": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3759": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3760": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3761": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3762": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3763": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3764": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3765": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3766": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3767": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3770": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3775": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3777": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3778": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3779": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3781": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3782": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3783": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3785": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3786": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3787": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3788": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3789": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3791": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3792": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3793": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3795": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3796": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3797": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3799": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3800": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3802": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3803": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3804": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3805": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3806": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3807": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3808": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3809": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3810": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3812": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3813": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3814": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3815": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3816": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3818": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3820": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3821": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3822": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3823": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3824": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3825": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3831": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3832": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3833": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3835": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3840": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3841": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3842": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3844": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3847": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3850": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3851": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3852": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3853": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3854": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3856": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3857": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3858": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3859": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3860": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3862": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3864": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3865": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3869": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3870": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3871": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3873": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3874": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3875": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3878": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3880": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3882": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3885": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3886": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3887": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3888": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3889": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3890": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3891": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3892": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3893": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3895": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3896": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3898": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3900": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3902": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3903": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3904": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3909": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3910": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3911": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3912": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3913": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3915": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3916": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3918": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3919": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3920": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3921": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3922": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3923": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3925": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3926": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3927": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3928": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3929": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3930": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3931": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3933": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3934": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3936": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3937": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3938": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3939": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3940": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3941": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3942": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3943": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3944": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3945": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3946": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3950": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3951": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3953": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3954": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3956": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3957": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3958": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3959": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3960": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3962": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3964": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3965": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3966": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3967": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3971": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3975": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3976": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3977": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3978": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3979": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3980": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3981": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3984": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3987": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3988": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3990": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3991": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3992": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3995": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "3996": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "4000": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "4001": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "4002": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4003": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4004": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4005": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4006": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4007": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4008": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "4009": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4010": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4011": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4012": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4013": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4014": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4017": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4018": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4019": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4020": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4021": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4022": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4025": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4029": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4030": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4031": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4032": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4034": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4035": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4036": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4037": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4051": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4053": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4054": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4055": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4059": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4060": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4061": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4064": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4065": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4066": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4067": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4068": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4069": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4070": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4072": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4073": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4074": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4075": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4076": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4077": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4078": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4101": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4102": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4103": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4104": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4105": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4106": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "4107": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4108": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4109": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4110": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4111": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4112": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4113": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4114": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4115": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4116": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4117": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4118": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4119": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4120": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4121": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4122": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4123": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4124": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4125": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4127": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4128": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4129": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4130": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4131": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4132": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4133": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4151": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4152": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4153": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4154": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4155": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4156": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4157": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4158": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4159": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4160": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4161": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4163": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4164": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4165": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4169": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4170": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4171": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4172": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4173": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4174": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4178": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4179": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4183": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4184": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4205": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4207": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4208": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4209": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4210": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4211": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4212": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4213": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4214": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4215": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4216": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4217": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4218": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4219": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4220": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4221": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4222": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4223": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4224": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4225": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4226": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4227": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4228": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4229": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4230": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4270": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4271": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4272": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4275": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4280": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4285": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4287": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4300": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4301": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4303": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4304": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4305": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4306": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4307": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4309": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4310": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4311": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4312": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4313": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4340": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4341": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4342": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4343": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4344": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4345": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4346": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4347": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4350": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4352": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4353": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4354": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4355": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4356": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4357": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4358": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4359": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4360": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4361": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4362": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4363": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4364": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4365": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4370": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4371": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4372": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4373": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4374": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4375": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "4376": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4377": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "4378": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4380": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "4381": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4382": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4383": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "4384": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4385": { state: { label: "New South Wales", value: "5" }, area: { label: "Rest of New South Wales", value: "12" } },
  "4387": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4388": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4390": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4400": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4401": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4402": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4403": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4404": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4405": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4406": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4407": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4408": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4410": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4411": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4412": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4413": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4415": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4416": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4417": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4418": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4419": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4420": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4421": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4422": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4423": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4424": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4425": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4426": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4427": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4428": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4454": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4455": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4461": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4462": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4465": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4467": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4468": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4470": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4471": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4472": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4474": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4475": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4477": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4478": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4479": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4480": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4481": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4482": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4486": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4487": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4488": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4489": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4490": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4491": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4492": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4493": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4494": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4496": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4497": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4498": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4500": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4501": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4502": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4503": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4504": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4505": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4506": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4507": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4508": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4509": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4510": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4511": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4512": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4514": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4515": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4516": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4517": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4518": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4519": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4520": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4521": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4550": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4551": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4552": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4553": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4554": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4555": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4556": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4557": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4558": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4559": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4560": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4561": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4562": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4563": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4564": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4565": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4566": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4567": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4568": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4569": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4570": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4571": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4572": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4573": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4574": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4575": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4580": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4581": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4600": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4601": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4605": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4606": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4608": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4610": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4611": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4612": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4613": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4614": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4615": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4620": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4621": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4625": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4626": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4627": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4630": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4650": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4655": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4659": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4660": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4662": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4670": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4671": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4673": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4674": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4676": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4677": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4678": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4680": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4694": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4695": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4697": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4699": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4700": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4701": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4702": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4703": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4704": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4705": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4706": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4707": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4709": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4710": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4711": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4712": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4713": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4714": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4715": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4716": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4717": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4718": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4719": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4720": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4721": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4722": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4723": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4724": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4725": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4726": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4727": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4728": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4730": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4731": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4732": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4733": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4735": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4736": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4737": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4738": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4739": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4740": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4741": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4742": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4743": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4744": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4745": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4746": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4750": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4751": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4753": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4754": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4756": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4757": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4798": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4799": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4800": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4801": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4802": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4803": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4804": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4805": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4806": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4807": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4808": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4809": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4810": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4811": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4812": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4813": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4814": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4815": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4816": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4817": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4818": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4819": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4820": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4821": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4822": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4823": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4824": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4825": { state: { label: "Northern Territory", value: "6" }, area: { label: "Rest of the Northern Territory", value: "16" } },
  "4828": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4829": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4830": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4849": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4850": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4852": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4854": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4855": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4856": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4857": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4858": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4859": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4860": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4861": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4865": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4868": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4869": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4870": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4871": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4872": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4873": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4874": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4875": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4876": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4877": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4878": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4879": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4880": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4881": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4882": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4883": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4884": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4885": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4886": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4887": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4888": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4890": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4891": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4892": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "4895": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "5000": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5001": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5005": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5006": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5007": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5008": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5009": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5010": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5011": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5012": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5013": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5014": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5015": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5016": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5017": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5018": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5019": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5020": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5021": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5022": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5023": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5024": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5025": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5031": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5032": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5033": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5034": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5035": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5037": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5038": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5039": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5040": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5041": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5042": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5043": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5044": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5045": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5046": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5047": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5048": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5049": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5050": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5051": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5052": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5061": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5062": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5063": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5064": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5065": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5066": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5067": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5068": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5069": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5070": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5071": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5072": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5073": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5074": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5075": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5076": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5081": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5082": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5083": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5084": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5085": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5086": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5087": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5088": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5089": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5090": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5091": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5092": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5093": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5094": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5095": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5096": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5097": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5098": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5106": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5107": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5108": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5109": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5110": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5111": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5112": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5113": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5114": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5115": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5116": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5117": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5118": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5120": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5121": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5125": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5126": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5127": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5131": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5132": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5133": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5134": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5136": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5137": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5138": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5139": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5140": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5141": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5142": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5144": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5150": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5151": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5152": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5153": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5154": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5155": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5156": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5157": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5158": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5159": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5160": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5161": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5162": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5163": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5164": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5165": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5166": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5167": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5168": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5169": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5170": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5171": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5172": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5173": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5174": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5201": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5202": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5203": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5204": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5210": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5211": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5212": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5213": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5214": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5220": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5221": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5222": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5223": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5231": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5232": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5233": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5234": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5235": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5236": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5237": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5238": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5240": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5241": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5242": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5243": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5244": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5245": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5246": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5250": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5251": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5252": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5253": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5254": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5255": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5256": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5259": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5260": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5261": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5262": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5263": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5264": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5265": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5266": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5267": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5268": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5269": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5270": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5271": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5272": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5273": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5275": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5276": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5277": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5278": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5279": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5280": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5290": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5291": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5301": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5302": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5303": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5304": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5306": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5307": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5308": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5309": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5310": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5311": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5320": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5321": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5322": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5330": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5331": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5332": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5333": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5340": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5341": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5342": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5343": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5344": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5345": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5346": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5350": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5351": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5352": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5353": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5354": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5355": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5356": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5357": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5360": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5371": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5372": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5373": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5374": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5381": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5400": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5401": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5410": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5411": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5412": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5413": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5414": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5415": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5416": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5417": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5418": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5419": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5420": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5421": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5422": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5431": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5432": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5433": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5434": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5440": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5451": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5452": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5453": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5454": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5455": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5460": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5461": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5462": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5464": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5470": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5471": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5472": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5473": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5480": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5481": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5482": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5483": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5485": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5490": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5491": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5493": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5495": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5501": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5502": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5510": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5520": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5521": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5522": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5523": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5540": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5550": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5552": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5554": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5555": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5556": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5558": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5560": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5570": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5571": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5572": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5573": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5575": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5576": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5577": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5580": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5581": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5582": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5583": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5600": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5601": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5602": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5603": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5604": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5605": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5606": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5607": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5608": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5609": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5630": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5631": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5632": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5633": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5640": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5641": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5642": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5650": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5651": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5652": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5653": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5654": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5655": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5660": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5661": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5670": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5671": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5680": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5690": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5700": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5701": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5710": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5713": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5715": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5717": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5719": { state: { label: "Australian Capital Territory", value: "1" }, area: { label: "Australian Capital Territory", value: "2" } },
  "5720": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5722": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5723": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5724": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5725": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5730": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5731": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5732": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5733": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5734": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5800": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5810": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5839": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5860": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5861": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5862": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5863": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5864": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5865": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5866": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5867": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5868": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5869": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5870": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5871": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5872": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5873": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5874": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5875": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5876": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5877": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5878": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5879": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5880": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5881": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5882": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5883": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5884": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5885": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5886": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5887": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5888": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5889": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5890": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5891": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5892": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5893": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5894": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5895": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5896": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5897": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5898": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5899": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5900": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5901": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5902": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5903": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5904": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5920": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5942": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "5950": { state: { label: "South Australia", value: "8" }, area: { label: "Adelaide", value: "1" } },
  "5960": { state: { label: "South Australia", value: "8" }, area: { label: "Rest of South Australia", value: "14" } },
  "6000": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6001": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6003": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6004": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6005": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6006": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6007": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6008": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6009": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6010": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6011": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6012": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6014": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6015": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6016": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6017": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6018": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6019": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6020": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6021": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6022": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6023": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6024": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6025": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6026": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6027": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6028": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6029": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6030": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6031": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6032": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6033": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6034": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6035": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6036": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6037": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6038": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6041": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6042": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6043": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6044": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6050": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6051": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6052": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6053": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6054": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6055": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6056": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6057": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6058": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6059": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6060": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6061": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6062": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6063": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6064": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6065": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6066": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6067": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6068": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6069": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6070": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6071": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6072": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6073": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6074": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6076": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6077": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6078": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6079": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6081": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6082": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6083": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6084": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6090": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6100": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6101": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6102": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6103": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6104": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6105": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6106": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6107": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6108": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6109": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6110": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6111": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6112": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6121": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6122": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6123": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6124": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6125": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6126": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6147": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6148": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6149": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6150": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6151": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6152": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6153": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6154": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6155": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6156": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6157": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6158": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6159": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6160": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6161": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6162": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6163": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6164": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6165": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6166": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6167": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6168": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6169": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6170": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6171": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6172": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6173": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6174": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6175": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6176": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6180": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6181": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6182": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6207": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6208": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6209": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6210": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6211": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6213": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6214": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6215": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6218": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6220": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6221": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6223": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6224": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6225": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6226": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6227": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6228": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6229": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6230": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6231": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6232": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6233": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6236": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6237": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6239": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6240": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6243": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6244": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6251": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6252": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6253": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6254": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6255": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6256": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6258": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6260": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6262": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6271": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6275": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6280": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6281": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6282": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6284": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6285": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6286": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6288": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6290": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6302": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6304": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6306": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6308": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6309": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6311": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6312": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6313": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6315": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6316": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6317": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6318": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6320": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6321": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6322": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6323": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6324": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6326": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6327": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6328": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6330": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6331": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6332": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6333": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6335": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6336": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6337": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6338": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6341": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6343": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6346": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6348": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6350": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6351": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6352": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6353": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6355": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6356": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6357": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6358": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6359": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6361": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6363": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6365": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6367": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6368": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6369": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6370": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6372": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6373": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6375": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6383": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6384": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6385": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6386": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6390": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6391": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6392": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6393": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6394": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6395": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6396": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6397": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6398": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6401": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6403": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6405": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6407": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6409": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6410": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6411": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6412": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6413": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6414": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6415": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6418": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6419": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6420": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6421": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6422": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6423": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6424": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6425": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6426": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6427": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6428": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6429": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6430": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6431": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6432": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6433": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6434": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6436": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6437": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6438": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6440": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6442": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6443": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6445": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6446": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6447": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6448": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6450": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6452": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6460": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6461": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6462": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6463": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6465": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6466": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6467": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6468": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6470": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6472": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6473": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6475": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6476": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6477": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6479": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6480": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6484": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6485": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6487": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6488": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6489": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6490": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6501": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6502": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6503": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6504": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6505": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6506": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6507": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6509": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6510": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6511": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6512": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6513": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6514": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6515": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6516": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6517": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6518": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6519": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6521": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6522": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6525": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6528": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6530": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6531": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6532": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6535": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6536": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6537": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6556": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6558": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6560": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6562": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6564": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6566": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6567": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6568": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6569": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6571": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6572": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6574": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6575": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6603": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6605": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6606": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6608": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6609": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6612": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6613": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6614": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6616": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6620": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6623": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6625": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6627": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6628": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6630": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6631": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6632": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6635": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6638": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6639": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6640": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6642": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6646": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6701": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6705": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6707": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6710": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6711": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6712": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6713": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6714": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6716": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6718": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6720": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6721": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6722": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6725": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6726": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6728": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6731": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6733": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6740": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6743": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6751": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6753": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6754": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6758": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6760": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6762": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6765": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6770": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6798": { state: { label: "Christmas Island", value: "2" }, area: { label: "Christmas Island", value: "4" } },
  "6799": { state: { label: "Cocos (Keeeling) Islands", value: "" }, area: { label: "Cocos (Keeeling) Islands", value: "5" } },
  "6800": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6803": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6809": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6817": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6820": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6827": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6830": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6831": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6832": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6837": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6838": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6839": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6840": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6841": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6842": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6843": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6844": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6845": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6846": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6847": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6848": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6849": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6850": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6865": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6872": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6892": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6900": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6901": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6902": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6903": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6904": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6905": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6906": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6907": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6909": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6910": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6911": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6912": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6913": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6914": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6915": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6916": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6917": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6918": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6919": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6920": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6921": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6922": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6923": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6924": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6925": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6926": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6929": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6931": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6932": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6933": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6934": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6935": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6936": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6937": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6938": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6939": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6940": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6941": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6942": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6943": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6944": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6945": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6946": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6947": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6951": { state: { label: "Western Australia", value: "11" }, area: { label: "Perth", value: "11" } },
  "6952": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6953": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6954": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6955": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6956": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6957": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6958": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6959": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6960": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6961": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6963": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6964": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6965": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6966": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6967": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6968": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6969": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6970": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6979": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6980": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6981": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6982": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6983": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6984": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6985": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6986": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6987": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6988": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6989": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6990": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6991": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6992": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "6997": { state: { label: "Western Australia", value: "11" }, area: { label: "Rest of Western Australia", value: "18" } },
  "7000": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7001": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7002": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7004": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7005": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7006": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7007": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7008": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7009": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7010": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7011": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7012": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7015": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7016": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7017": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7018": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7019": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7020": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7021": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7022": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7023": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7024": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7025": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7026": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7027": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7030": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7050": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7051": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7052": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7053": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7054": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7055": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7109": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7112": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7113": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7116": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7117": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7119": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7120": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7139": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7140": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7150": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7151": { state: { label: "Heard Island and McDonald Islands", value: "4" }, area: { label: "Heard Island and McDonald Islands", value: "7" } },
  "7155": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7162": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7163": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7170": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7171": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7172": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7173": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7174": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7175": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7176": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7177": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7178": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7179": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7180": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7182": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7183": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7184": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7185": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7186": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7187": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7190": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7209": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7210": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7211": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7212": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7213": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7214": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7215": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7216": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7248": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7249": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7250": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7252": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7253": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7254": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7255": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7256": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7257": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7258": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7259": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7260": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7261": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7262": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7263": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7264": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7265": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7267": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7268": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7270": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7275": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7276": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7277": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7290": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7291": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7292": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7300": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7301": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7302": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7303": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7304": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7305": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7306": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7307": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7310": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7315": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7316": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7320": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7321": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7322": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7325": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7330": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7331": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7466": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7467": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7468": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7469": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7470": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7800": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7802": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7803": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7804": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7805": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7806": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7807": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7808": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7809": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7810": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7811": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7812": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7813": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7814": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7823": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7824": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7827": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7828": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7829": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7845": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7850": { state: { label: "Tasmania", value: "9" }, area: { label: "Hobart", value: "8" } },
  "7901": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7902": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7903": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7904": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7905": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7906": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7907": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7908": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7915": { state: { label: "Tasmania", value: "9" }, area: { label: "Rest of Tasmania", value: "15" } },
  "7916": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7917": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7918": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7919": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7920": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7921": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7922": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "7923": { state: { label: "Tasmania", value: "9" }, area: { label: "Launceston", value: "9" } },
  "8001": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8002": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8003": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8004": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8005": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8006": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8007": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8008": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8009": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8010": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8011": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8012": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "8045": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8051": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8060": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8061": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8066": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8069": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8070": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8071": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8102": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8103": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8107": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8108": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8111": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8120": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8205": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8383": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8386": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8388": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8390": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8393": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8394": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8396": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8399": { state: { label: "Victoria", value: "10" }, area: { label: "Melbourne", value: "10" } },
  "8627": { state: { label: "Victoria", value: "10" }, area: { label: "Rest of Victoria", value: "17" } },
  "9000": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9001": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9002": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9005": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9007": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9008": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9009": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9010": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9013": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9015": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9016": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9017": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9018": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9019": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9020": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9021": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9022": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9023": { state: { label: "Queensland", value: "7" }, area: { label: "Brisbane", value: "3" } },
  "9464": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "9726": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "9943": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
  "9944": { state: { label: "Queensland", value: "7" }, area: { label: "Rest of Queensland", value: "13" } },
};


export const AUSTRALIA_ZIP_CODE = Object.keys(AustraliaPostalCodeData);