// All States of America
export const USState = [
  { state: "Iowa", value: "iowa" },
  { state: "New York", value: "newyork" },
  { state: "Alabama", value: "alabama" },
  { state: "Alaska", value: "alaska" },
  { state: "Arizona", value: "arizona" },
  { state: "Arkansas", value: "arkansas" },
  { state: "California", value: "california" },
  { state: "Colorado", value: "colorado" },
  { state: "Connecticut", value: "connecticut" },
  { state: "Delaware", value: "delaware" },
  { state: "Florida", value: "florida" },
  { state: "Georgia", value: "georgia" },
  { state: "Hawaii", value: "hawaii" },
  { state: "Idaho", value: "idaho" },
  { state: "Illinois", value: "illinois" },
  { state: "Indiana", value: "indiana" },
  { state: "Kansas", value: "kansas" },
  { state: "Kentucky", value: "kentucky" },
  { state: "Louisiana", value: "louisiana" },
  { state: "Maine", value: "maine" },
  { state: "Maryland", value: "maryland" },
  { state: "Massachusetts", value: "massachusetts" },
  { state: "Michigan", value: "michigan" },
  { state: "Minnesota", value: "minnesota" },
  { state: "Mississippi", value: "mississippi" },
  { state: "Missouri", value: "missouri" },
  { state: "Montana", value: "montana" },
  { state: "Nebraska", value: "nebraska" },
  { state: "Nevada", value: "nevada" },
  { state: "New Hampshire", value: "newhampshire" },
  { state: "New Jersey", value: "newjersey" },
  { state: "New Mexico", value: "newmexico" },
  { state: "North Carolina", value: "northcarolina" },
  { state: "North Dakota", value: "northdakota" },
  { state: "Ohio", value: "ohio" },
  { state: "Oklahoma", value: "oklahoma" },
  { state: "Oregon", value: "oregon" },
  { state: "Pennsylvania", value: "pennsylvania" },
  { state: "Rhode Island", value: "rhodeisland" },
  { state: "South Carolina", value: "southcarolina" },
  { state: "South Dakota", value: "southdakota" },
  { state: "Tennessee", value: "tennessee" },
  { state: "Texas", value: "texas" },
  { state: "Utah", value: "utah" },
  { state: "Vermont", value: "vermont" },
  { state: "Virginia", value: "virginia" },
  { state: "Washington", value: "washington" },
  { state: "West Virginia", value: "westvirginia" },
  { state: "Wisconsin", value: "wisconsin" },
  { state: "Wyoming", value: "wyoming" },
];

// List of Australia's States
export const australiaState = [
  {
    state: "Australian Capital Territory",
    value: "australiancapitalterritory",
  },
  { state: "New South Wales", value: "newsouthwales" },
  { state: "Northern Territory", value: "northernterritory" },
  { state: "Queensland", value: "queensland" },
  { state: "South Australia", value: "southaustralia" },
  { state: "Tasmania", value: "tasmania" },
  { state: "Victoria", value: "victoria" },
  { state: "Western Australia", value: "westernaustralia" },
];

// List of Canada's States

export const canadaStates = [

  { state: "Alberta", value: "alberta" },
  { state: "British Columbia", value: "britishcolumbia" },
  { state: "Manitoba", value: "manitoba" },
  { state: "New Brunswick", value: "newbrunswick" },
  { state: "Newfoundland and Labrador", value: "newfoundlandandlabrador" },
  { state: "Northwest Territories", value: "northwestterritories" },
  { state: "Nova Scotia", value: "novascotia" },
  { state: "Nunavut", value: "nunavut" },
  { state: "Ontario", value: "ontario" },
  { state: "Prince Edward Island", value: "princeedwardisland" },
  { state: "Quebec", value: "quebec" },
  { state: "Saskatchewan", value: "saskatchewan" },
  { state: "Yukon", value: "yukon" },
];

// List of United Kingdom's States
export const UKStates = [
  { state: "England", value: "england" },
  { state: "Scotland", value: "scotland" },
  { state: "Wales", value: "wales" },
  { state: "Northern Ireland", value: "northernireland" },
];

// List of NewZealand States
export const NewZealandStates = [
  { state: "Auckland", value: "auckland" },
  { state: "Bay of Plenty", value: "bayofplenty" },
  { state: "Canterbury", value: "canterbury" },
  { state: "Chatham Islands Territory", value: "chathamislandsterritory" },
  { state: "Gisborne", value: "gisborne" },
  { state: "Hawke's Bay", value: "hawkesbay" },
  { state: "Manawatu-Wanganui", value: "manawatuwanganui" },
  { state: "Marlborough", value: "marlborough" },
  { state: "Nelson", value: "nelson" },
  { state: "Northland", value: "northland" },
  { state: "Otago", value: "otago" },
  { state: "Southland", value: "southland" },
  { state: "Taranaki", value: "taranaki" },
  { state: "Tasman", value: "tasman" },
  { state: "Waikato", value: "waikato" },
  { state: "Wellington", value: "wellington" },
  { state: "West Coast", value: "westcoast" },
];

export const IndiaStates = [
  { state: "Andhra Pradesh", value: "andhra pradesh" },
  { state: "Arunachal Pradesh", value: "arunachal pradesh" },
  { state: "Assam", value: "assam" },
  { state: "Bihar", value: "bihar" },
  { state: "Chhattisgarh", value: "chhattisgarh" },
  { state: "Goa", value: "goa" },
  { state: "Gujarat", value: "gujarat" },
  { state: "Haryana", value: "haryana" },
  { state: "Himachal Pradesh", value: "himachal pradesh" },
  { state: "Jharkhand", value: "jharkhand" },
  { state: "Karnataka", value: "karnataka" },
  { state: "Kerala", value: "kerala" },
  { state: "Madhya Pradesh", value: "madhya pradesh" },
  { state: "Maharashtra", value: "maharashtra" },
  { state: "Manipur", value: "manipur" },
  { state: "Meghalaya", value: "meghalaya" },
  { state: "Mizoram", value: "mizoram" },
  { state: "Nagaland", value: "nagaland" },
  { state: "Odisha", value: "odisha" },
  { state: "Punjab", value: "punjab" },
  { state: "Rajasthan", value: "rajasthan" },
  { state: "Sikkim", value: "sikkim" },
  { state: "Tamil Nadu", value: "tamil nadu" },
  { state: "Telangana", value: "telangana" },
  { state: "Tripura", value: "tripura" },
  { state: "Uttar Pradesh", value: "uttar pradesh" },
  { state: "Uttarakhand", value: "uttarakhand" },
  { state: "West Bengal", value: "west bengal" },
];

export const genderList = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
  { value: "DECLINE", label: "Decline" },
];
export const ethnicity = [
  "White",
  "Black or African",
  "American Indian or Alaska Native",
  "Asian",
  "Native Hawaiian and Other Pacific Islander",
  "Latino/Hispanic",
  "Other",
];

export const maritalStatus = [
  "Never Married",
  "Married",
  "Separated",
  "Divorced",
  "Widowed",
];

export const affiliation = [
  "Catholic (incl. Roman Catholic, Anglican, Orthodox, Baptist, Lutheran)",
  "Muslim",
  "Jewish",
  "Hindu",
  "Buddhist",
  "Sikh",
  "Agnostic (not sure if there is a God)",
  "Atheist (do not believe in god)",
  "Nothing in particular",
  "Prefer not to Answer",
  "Christian",
  "Other",
];

export const personallyOwn = [
  "Dog",
  "Cat",
  "Credit Card",
  "House (own outright or with a mortgage)",
  "Car (own outright, with a loan, or through a lease)",
  "An Android mobile phone",
  "An Apple mobile phone",
  "None of the above",
];

export const jobType = [
  "Employed Full-Time (35 Hours or more each week)",
  "Part-Time (34 Hours or less search week)",
  "Self-Employed",
  "Retired",
  "Student",
  "Unemployed",
  "Homemaker",
];

export const department = [
  "Account/Finance",
  "Administration",
  "Clerical",
  "Communications/PR",
  "Creative Services",
  "Customer Services",
  "Design/Engineering",
  "Executive/Owner",
  "General Management",
  "Human Resource",
  "Information Technology",
  "Infrastructure (Non-IT)",
  "Interior Design",
  "Janitorial",
  "Legal",
  "Manufacturing",
  "Marketing/Advertising",
  "Operations/Production",
  "Planing",
  "Project Management",
  "Quality Control",
  "Research and Development",
  "Sales/Business Development",
  "Security",
  "Supply Chain/Demand Management",
  "Warehouse/Transportation/Shipping",
  "Other",
];

export const educationLevel = [
  "Some high school, no diploma",
  "High school graduate, diploma or the equivalent (for example: GED)",
  "Some college credit, no degree",
  "Trade/technical/vocational training",
  "Associate degree",
  "Bachelor's degree",
  "Master's degree",
  "Professional degree",
  "Doctorate degree",
];

export const householdIncome = [
  "Less than $25,000",
  "$25,000 - $50,000",
  "$50,000 - $100,000",
  "$100,000 - $200,000",
  "More than $200,000",
];

export const typeOfDiabetes = ["Type I Diabetes ", "Type II Diabetes "];

export const multipleSlerosis = [
  "Multiple Sclerosis (MS): Clinically Isolated Syndrome (CIS)",
  "Multiple Sclerosis (MS): Primary Progressive  MS (PPMS)",
  "Multiple Sclerosis (MS): Progressive Relapsing MS (PRMS)",
  "Multiple Sclerosis (MS): Relapsing Remitting MS (RRMS)",
  "Multiple Sclerosis (MS): Secondary Progressive MS (SPMS)",
  "None or I don't know",
  "Other: Please specify, if nothing is listed or leave empty if you don't know.",
];

export const survey = [
  "Patient & Caregiver Surveys & Interviews Only",
  "Online Interviews",
  "Business Surveys & Interviews Only",
  "Consumer Surveys",
];

export const dieses = [
  "ABCD Syndrome",
  "Achalasia",
  "Acne",
  "Acoustic Neuroma (Vestibula Schwannoma)",
  "Acromegaly",
  "Adams-Oliver Syndrome (AOS)",
  "Addiction",
  "Addison's Disease (Adrenal insufficiency)",
  "Adenomyosis",
  "Adhesive Capsulitis (Frozen Shoulder)",
  "Adrenal Cortical Carcinoma (ACC)",
  "Agenesis of the Corpus Callosum (ACC)",
  "Aicardi Syndrome",
  "Alagille Syndrome (AS)",
  "Albinism",
  "Alkaptonuria",
  "Allergies",
  "Alopecia",
  "Alpha 1 Antitrypsin Deficiency (A1AD)",
  "Alternating Hemiplegia of Childhood (AHC)",
  "Alzheimer's Disease (AD)",
  "Amniotic Band Syndrome",
  "Amplified Musculoskeletal Pain Syndrome",
  "Amputation",
  "Amyloidosis",
  "Amyotrophic Lateral Sclerosis (ALS)",
  "Anal Cancer",
  "Anemia",
  "Aneurysm",
  "Anhidrosis (Hypohydrosis)",
  "Ankylosing Spondylitis (AS)",
  "Anosmia (loss of smell)",
  "Anoxic Brain Injury",
  "Antiphospholipid Syndrome (APS)",
  "Antisynthetase Syndrome",
  "Anxiety",
  "Aortic Disease",
  "Aphantasia",
  "Aphasia",
  "Aplastic Anemia (AA)",
  "Appendix Cancer",
  "Apraxia",
  "Arachnoid Cyst",
  "Arachnoiditis",
  "Arteriovenous Malformation (AVM)",
  "Arthrogryposis Multiplex Congenita (AMC)",
  "Aspirin-like defect (ALD)",
  "Asthma",
  "Ataxia",
  "Atrial Fibrillation (AFIB)",
  "Atrophic Vaginitis",
  "Attention Deficit Hyperactivity Disorder (ADH, ADD)",
  "Atypical Hemolytic Uremic Syndrome (aHUS)",
  "Auditory Processing Disorder (APD)",
  "Autism",
  "Autoimmune Disease",
  "Autoimmune Encephalitis (AE)",
  "Avascular Necrosis (AV, Osteonecrosis)",
  "Barrett's Esophagus",
  "Barth Syndrome (BTHS)",
  "Bartonella",
  "Basal Cell Carcinoma (BCC)",
  "Batten Disease",
  "Beckwith-Wiedemann Syndrome (BWS)",
  "Behcet's Disease (BD)",
  "Bell's Palsy",
  "Benign Prostatic Hyperplasia (BP, Enlarged Prostate)",
  "Beta Thalassemia (Cooley s Anemia)",
  "Bile Acid Malabsorption (BAD)",
  "Bile Duct Cancer (Cholangiocarcinoma)",
  "Bipolar",
  "Bladder Cancer",
  "Blood Cancer",
  "Blood Clot",
  "Blood Clotting Disorder",
  "Blount's Disease",
  "Bone Cancer",
  "Borderline Personality Disorder (BPD)",
  "Brain Aneurysm",
  "Brain Cancer",
  "Brain Damage",
  "Brain Tumor",
  "BRCA Mutation",
  "Breast Cancer (BC)",
  "Breast Cancer in Men",
  "Breast Implant Illness",
  "Bronchiectasis",
  "Bronchitis",
  "Bronchopulmonary Dysplasia (BPD)",
  "Bulging Disc",
  "Bursitis",
  "Calcinosis",
  "Calciphylaxis",
  "Campomelic Dysplasia",
  "Cancer",
  "Candidiasis (Yeast Infection)",
  "Capillary Lymphatic Venous Malformations (CLVMs)",
  "Carcinoid Cancer",
  "Carcinoma",
  "Cardiofaciocutaneous Syndrome (CFC syndrome)",
  "Carnitine Deficiency",
  "Caroli Disease",
  "Carpal Tunnel Syndrome (CTS)",
  "Castleman Disease (CD)",
  "Catheter",
  "Cauda Equina Syndrome",
  "Celiac Disease (CCD)",
  "Cellulitis",
  "Central Pain Syndrome (CPS)",
  "Cerebellar Atrophy",
  "Cerebral Amyloid Angiopathy",
  "Cerebral Cavernous Malformations (CCMs)",
  "Cerebral Palsy (CP)",
  "Cervical Cancer",
  "Cervical Dystonia",
  "Charge Syndrome",
  "Chiari Malformation (CM)",
  "Chilblains (Pernio)",
  "Chronic Fatigue Syndrome (CF, ME)",
  "Chronic Inflammatory Demyelinating Polyneuropathy (CIDP)",
  "Chronic Intestinal Pseudo-obstruction (CIPO)",
  "Chronic obstructive pulmonary disease (COPD)",
  "Chronic Pain",
  "Chronic Venous Insufficiency (CVI)",
  "Cleft",
  "Clostridium difficile (C Diff)",
  "Clubfoot",
  "Cluster Headache",
  "Coffin-Lowry Syndrome (CLS)",
  "Cognitive Impairment (CI)",
  "Colon Polyps",
  "Colonic Inertia",
  "Color Blind",
  "Colorectal (Colon) Cancer",
  "Complex Regional Pain Syndrome (CRP, RSD)",
  "Congenital Adrenal Hyperplasia (CAH)",
  "Congenital Central Hypoventilation Syndrome (CCHS)",
  "Congenital Myopathy",
  "Congenital Sucrase-Isomaltase Deficiency (CSID)",
  "Constipation",
  "Conversion Disorder (CD)",
  "Coronavirus (COVID-19)",
  "Cortical Dysplasia",
  "Costochondritis",
  "Cowden Syndrome (CS)",
  "Craniocervical Instability (CCI)",
  "Craniosynostosis",
  "Crohn's Disease (CD)",
  "Cushing's Disease (CS)",
  "Cyclic Vomiting Syndrome (CVS)",
  "Cystic Fibrosis (CF)",
  "Cystic Hygroma",
  "Cystinosis",
  "Cytomegalovirus (CMV)",
  "Dandy Walker Syndrome (DWS)",
  "Danon Disease",
  "Darier Disease (DD Keratosis Follicularis)",
  "Deep Vein Thrombosis (DVT)",
  "Degenerative Disc Disease (DDD)",
  "Deletion Duplication Syndrome",
  "Delta-Storage Pool Deficiency",
  "Depression (MDD)",
  "Dercum's Disease (adiposis dolorosa)",
  "Dermatomyositis (DM)",
  "Desmoid Tumor",
  "Developmental Delay (DD)",
  "Diabetes (DM)",
  "Disabled",
  "Disruptive Mood Dysregulation Disorder (DMDD)",
  "Dissociative Identity Disorder (DI, Multiple Personality Disorder)",
  "Diverticulitis",
  "Down Syndrome (DS)",
  "Dry Mouth",
  "Duodenitis",
  "Dupuytren's Contracture",
  "Dwarfism",
  "Dysarthria (Slurred Speech)",
  "Dysautonomia",
  "Dyskeratosis Congenita",
  "Dyslexia",
  "Dysmenorrhea (Painful Menstruation)",
  "Dysphagia (Difficulty Swallowing)",
  "Dystonia",
  "Ear Cancer",
  "Eating Disorder",
  "Eczema (Atopic Dermatitis)",
  "Edema",
  "Ehlers-Danlos Syndrome (EDS)",
  "Elephantiasis (Lymphatic Filariasis)",
  "Empty Sella Syndrome (ESS)",
  "Encephalitis",
  "Encephalocele",
  "Endometrial Cancer",
  "Endometriosis",
  "Enlarged Spleen (Splenomegaly)",
  "Eosinophilic Colitis (EC)",
  "Eosinophilic Esophagitis (EOE)",
  "Eosinophilic Gasteroenteritis (EG)",
  "Epicondylitis (Tennis Elbow)",
  "Epidermolysis Bullosa (EB)",
  "Epilepsy",
  "Epithelial Basement Membrane Dystrophy",
  "Epstein Barr Syndrome (EBV)",
  "Erb's Palsy",
  "Erectile Dysfunction (ED)",
  "Erythema Nodosum",
  "Erythromelalgia",
  "Esophageal Atresia (EA)",
  "Esophageal Cancer",
  "Esophageal Varices",
  "Essential Thrombocythemia",
  "Essential Tremor",
  "Ewing's Sarcoma",
  "Exocrine Pancreatic Insufficiency (EPI)",
  "Eye Cancer",
  "Fabry disease",
  "Failure to Thrive (FTT)",
  "Familial Adenomatous Polyposis (FAP)",
  "Familial Hypercholesterolemia (FH)",
  "Familial Mediterranean Fever (FMF)",
  "Fatty Oxidation Disorder (FAOD)",
  "Feeding Tubes",
  "Fetal Alcohol Syndrome (FAS)",
  "Fibrocystic Breasts",
  "Fibrodysplasia Ossificans Progressiva (FOP)",
  "Fibromuscular Dysplasia (FMD)",
  "Fibromyalgia (FM)",
  "Fibrosing Mediastinitis",
  "Fibrous Dysplasia",
  "Fistula",
  "Foot Drop",
  "FOXG1 Syndrome",
  "Fragile X Syndrome (FXS)",
  "Friedreich's Ataxia (FA)",
  "Functional Neurological Disorder (FND)",
  "G6PD deficiency (glucose-6-phosphate-dehydrogenase deficiency)",
  "Galactosemia",
  "Gall Stones",
  "Ganglioneuroblastoma",
  "Gastric Bypass",
  "Gastric Dysmotility",
  "Gastritis",
  "Gastroesophageal Reflux Disease (GERD)",
  "Gastrointestinal Stromal Tumor (GIST)",
  "Gastroparesis (GP)",
  "Gastroschisis",
  "Gaucher Disease (GD)",
  "Gender Dysphoria",
  "Giant Cell Arteritis (GCA)",
  "Glucose Transporter Deficiency (GLUT1DS)",
  "Gluten Intolerance",
  "Glycogen Storage Disease (GSD)",
  "Goiter",
  "Goldenhar Syndrome",
  "Gout",
  "Graft Versus Host Disease (GVHD)",
  "Granular Cell Tumors",
  "Granuloma Annulare (GA)",
  "Grave's Disease (GD)",
  "Guillain-Barre Syndrome (GBS)",
  "Hansen's Disease (Leprosy)",
  "Hashimoto's Disease (HT)",
  "Head and Neck Cancer",
  "Headache",
  "Hearing Aid",
  "Hearing Loss",
  "Heart Disease",
  "HELLP Syndrome",
  "Hemangioma",
  "Hemihyperplasia",
  "Hemochromatosis (Iron Overload)",
  "Hemophilia",
  "Hemorrhoids",
  "Hepatitis",
  "Hereditary alpha-tryptasemia (H?T)",
  "Hereditary Angioedema (HAE)",
  "Hereditary hemorrhagic telangiectasia (HHT)",
  "Hereditary Spastic Paraparesis (HSP)",
  "Hereditary Spastic Paraplegia (HSP)",
  "Hernia",
  "Herpes (HSV)",
  "Hidradenitis Suppurativa (HS)",
  "High Blood Pressure (Hypertensio, HBP)",
  "High Cholesterol (Hyperlipidemia)",
  "Hip Dysplasia",
  "Hip Replacement",
  "Hirschsprung's Disease (H, HSCR)",
  "Hirsutism",
  "HIV",
  "Hodgkins Lymphoma",
  "Holoprosencephaly (HPE)",
  "Homocystinuria (HCU)",
  "Horner Syndrome",
  "Human Growth Hormone Deficiency (GHD)",
  "Human Papillomavirus (Human Papillomavirus (HPV)",
  "Huntington's Disease (HD)",
  "Hydrocephalus",
  "Hyper-IgA Syndrome",
  "Hyperaldosteronism",
  "Hyperemesis Gravidarum (H, Morning Sickness)",
  "Hypereosinophilic Syndrome (HES)",
  "Hyperhidrosis",
  "Hyperkalemia",
  "Hypermobility Syndrome",
  "Hyperoxaluria",
  "Hyperparathyroidism",
  "Hypersomnia",
  "Hyperthyroidism",
  "Hypocalcemia",
  "Hypoglycemia (Low Blood Sugar)",
  "Hypohidrotic Ectodermal Dysplasia (HED)",
  "Hypokalemia",
  "Hypokalemic Periodic Paralysis",
  "Hyponatremia",
  "Hypoparathyroidism",
  "Hypophosphatasia (HPP)",
  "Hypoplasia of the Corpus Callosum",
  "Hypothyroidism",
  "Hypotonia",
  "Hypoxic Ischemic Encephalopathy (HIE)",
  "Hysterectomy",
  "Ichthyosis",
  "IgG 4 related disease (IgG4-RD)",
  "Immune Thrombocytopenic Purpura (ITP)",
  "Immunoglobulin Monoclonal Gammopathy of Undetermined Significance (IgM-MGUS)",
  "Incontinence",
  "Incontinentia Pigmenti (IP)",
  "Infertility",
  "Insomnia",
  "Intellectual Disability",
  "Intermittent Explosive Disorder (IED)",
  "Interstitial Cystitis (IC)",
  "Interstitial Lung Disease (ILD)",
  "Intracranial Hemorrhage (Brain Bleeds)",
  "Intracranial Hypertension (I, Pseudotumor Cerebri)",
  "intracranial Pressure (ICP)",
  "Iritis",
  "Irritable Bowel Syndrome (IBS)",
  "Ischemic Colitis",
  "Jansen's Metaphyseal Chondrodysplasia",
  "Juvenile Arthritis (JA)",
  "Kabuki Syndrome",
  "Kaposi Sarcoma (KS)",
  "Kawasaki Disease",
  "Keratosis Pilaris (follicular keratosis)",
  "Kidney Disease",
  "Klinefelter Syndrome (XXY Syndrome)",
  "Klippel-Feil Syndrome (KFS)",
  "Klippel-Trenaunay Syndrome (KTS)",
  "Knee Replacement",
  "Lactose Intolerance",
  "Lamb-Shaffer Syndrome",
  "Lambert-Eaton myasthenic syndrome (LEMS)",
  "Landau Kleffner Syndrome (LKS)",
  "Langerhans Cell Histiocytosis (LCH)",
  "Larsen syndrome",
  "Laryngeal Cancer",
  "Laryngomalacia",
  "Laurence-Moon-Bardet-Biedl syndrome (LMBBS)",
  "Learning Disability",
  "Legg-Calve-Perthes Disease (LCPD)",
  "Leiomyosarcoma",
  "LEOPARD Syndrome",
  "Leukemia",
  "Leukocytosis",
  "Leukodystrophy",
  "Leukopenia",
  "Lichen Planus",
  "Lichen Sclerosis (LS)",
  "Lipedema",
  "Lipodystrophy",
  "Liposarcoma",
  "Lissencephaly",
  "Livedo Reticularis (LR)",
  "Liver Cancer",
  "Liver Disease (not hepatitis)",
  "Loeys Dietz Syndrome",
  "Loin Pain Hematuria Syndrome (LPHS)",
  "Loss of Mobility",
  "Low Blood Pressure (Hypotension)",
  "Low Testosterone",
  "Lung Cancer",
  "Lung Disease",
  "Lupus",
  "Lyme Disease",
  "Lymphedema",
  "Lymphocytic Colitis",
  "Lymphoma",
  "Lymphoproliferative Disease (LPD)",
  "Lynch Syndrome (Hereditary Nonpolyposis Colorectal Cancer HNPCC)",
  "Macular Degeneration",
  "Macular Telangiectasia (MacTel)",
  "Mal de debarquement Syndrome (MdDS)",
  "Malabsorption",
  "Malignant Hyperthermia (MH)",
  "Maple Syrup Urine Disease (MSUD)",
  "Marfan Syndrome (MFS)",
  "Mastocytosis (Mast Cell Activating Diseas, MCAD)",
  "May Thurner Syndrome",
  "Mayer-Rokitansky-K ster-Hauser (MRKH) syndrome",
  "McCune-Albright Syndrome (MAS)",
  "Median arcuate ligament syndrome (MALS)",
  "Memory Loss",
  "Meniere's Disease (MD)",
  "Menopause",
  "Menorrhagia (Heavy Menstrual Bleedin, Heavy Periods)",
  "Mental Illness",
  "Mesothelioma",
  "Metabolic Syndrome",
  "Metatarsalgia",
  "Methicillin-Resistant Staphylococcus Aureus (MRSA)",
  "Microcephaly",
  "Microscopic Colitis",
  "Microtia",
  "Migraine",
  "Mitochondrial Disease",
  "Mixed Connective Tissue Disease (MCTD)",
  "Moebius Syndrome",
  "Monoclonal gammopathy of undetermined significance (MGUS)",
  "Mood Disorder",
  "Morgellons Disease (MD)",
  "Moyamoya Disease",
  "MTHFR",
  "Mucopolysaccharidoses (MPS)",
  "Multiple Endocrine Neoplasia (MEN)",
  "Multiple Myeloma (MM)",
  "Multiple Sclerosis (MS)",
  "Multiple System Atrophy (MSA)",
  "Muscle Spasms",
  "Muscle Tension Dysphonia (MTD)",
  "Muscular Dystrophy (MD)",
  "Myasthenia Gravis (MG)",
  "Mycobacterium Avium Complex (MAC)",
  "Myelin oligodendrocyte glycoprotein Antibody-Associated Disease (MOG-Ab)",
  "Myelodysplastic syndrome (MDS)",
  "Myelofibrosis",
  "Myeloproliferative Neoplasms",
  "Myopathy",
  "Myositis",
  "Narcolepsy",
  "Nasal Polyps",
  "Nasopharyngeal Carcinoma (NPC)",
  "Neck Cancer",
  "Neck Surgery",
  "Neuralgia",
  "Neuroblastoma",
  "Neuroendocrine Cancer (NET)",
  "Neurofibromatosis (NF)",
  "Neurogenic Bladder",
  "Neurogenic Bowel",
  "Neuromyelitis optica (NMO)",
  "Neuropathy",
  "Neurotrophic Keratitis",
  "Neutropenia",
  "Niemann-Pick Disease",
  "Non-Hodgkin's Lymphoma (NHL)",
  "Non-radiographic Axial Spondyloarthritis (nrAxSpA)",
  "Nonspecific Interstitial Pneumonia (NSIP)",
  "Nontuberculous Mycobacterial (NTM)",
  "Noonan Syndrome (NS)",
  "Obesity",
  "Obsessive Compulsive Disorder (OCD)",
  "Oppositional Defiant Disorder (ODD)",
  "Oral Cancer",
  "Organ Transplant",
  "Organic Acidemia (OA)",
  "Osteoarthritis (OA)",
  "Osteogenesis Imperfecta (OI)",
  "Osteomyelitis (OM)",
  "Osteopenia",
  "Osteoporosis",
  "Ostomy",
  "Ovarian Cancer",
  "Ovarian Cyst",
  "Overactive Bladder (OAB)",
  "Paget's",
  "Palmoplantar Pustulosis",
  "Pancreatic Cancer",
  "Pancreatitis",
  "Paraganglioma",
  "Paralysis",
  "Paraneoplastic Syndrome",
  "Parkinson's Disease (PD)",
  "Paroxysmal Nocturnal Hemoglobinuria (PNH)",
  "Pediatric Autoimmune Neuropsychiatric Disorder Associated with Strep.(PANDAS)",
  "Pelvic Congestion Syndrome (PCS)",
  "Pelvic Floor Dysfunction",
  "Pelvic Inflammatory Disease (PID)",
  "Pemphigus / Pemphigoid",
  "Pendred Syndrome",
  "Periodic Limb Movement Disorder (PLMD)",
  "Periodontitis (Gum Disease)",
  "Perivascular Epithelioid Cell Tumour (PEComas)",
  "Periventricular Leukomalacia (PVL)",
  "Persistent Genital Arousal Disorder",
  "Personality Disorder",
  "Peyronie's Disease (Penile Fibrosis)",
  "Pfeiffer Syndrome",
  "Phenylketonuria (PKU)",
  "Pheochromocytoma (PCC)",
  "Pierre Robin Sequence (PRS)",
  "Pituitary Tumor",
  "Plagiocephaly (Flat Head Syndrome)",
  "Plantar Fasciitis (PF)",
  "Pneumonia",
  "Poland Syndrome",
  "Polio",
  "Polychrondritis",
  "Polycystic Ovarian Syndrome (PCOS)",
  "Polycythemia Vera (PV)",
  "Polymicrogyria (PMG)",
  "Polymyalgia Rheumatica (PMR)",
  "Polymyositis (PM)",
  "Pompe Disease",
  "Popliteal Artery Entrapment Syndrome (PAES)",
  "Porphyria",
  "Post-Polio Syndrome (PPS)",
  "Posttraumatic Stress Disorder (PTSD)",
  "Pre-exposure Prophylaxis (PrEP)",
  "Precocious Puberty",
  "Premature Ovarian Failure (POF) (Primary Ovarian Insufficiency POI)",
  "Premenstrual Dysphoric Disorder (PMDD)",
  "Primary Ciliary Dyskinesia (PCD)",
  "Primary Hyperoxaluria (PH)",
  "Primary Immunodeficiency (PID)",
  "Primary Peritoneal",
  "Progressive Multifocal Leukoencephalopathy (PML)",
  "Progressive Supranuclear Palsy (PSP)",
  "Prolactinoma",
  "Prostate Cancer",
  "Protein Deficiency",
  "Proteinuria (Protein in Urine)",
  "Prurigo Nodularis (PN)",
  "Pseudobulbar Affect (PBA)",
  "Psoriasis",
  "Psoriatic Arthritis (PsA)",
  "Ptosis (Drooping ey, Blepharoptosis)",
  "Pulmonary Arterial Hypertension (PAH)",
  "Pulmonary Embolism (PE)",
  "Pulmonary Fibrosis (PF)",
  "Pyloric Stenosis (Gastric Outlet Obstruction)",
  "Pyoderma Gangrenosum (PG)",
  "Pyruvate Kinase Deficiency (PKD)",
  "Raynaud's Syndrome",
  "Rectal Cancer (CRC)",
  "Rectocele",
  "Renal Cell Carcinoma (Kidney Cance, RCC)",
  "Respiratory Syncytial Virus (RSV) Infection",
  "Restless Leg Syndrome (RLS)",
  "Retroperitoneal Fibrosis",
  "Rheumatoid Arthritis (RA)",
  "Rickets (Osteomalacia)",
  "Rocky Mountain Spotted Fever (RMSF)",
  "Rosacea",
  "Rubella- German Measles",
  "Sacroiliac Joint Disease (SI)",
  "Sarcoidosis",
  "Sarcoma",
  "Schamberg's Disease",
  "Scheuermann's Kyphosis",
  "Schinzel-Giedion Syndrome (SGS)",
  "Schizencephaly",
  "Schizophrenia",
  "Sciatica",
  "Scleroderma",
  "Sclerosing Peritonitis (SP)",
  "Scoliosis",
  "Secondary Adrenal insufficiency",
  "Sensory Processing Disorder (SPD)",
  "Sepsis",
  "Sexually Transmitted Disease",
  "Shaken Baby Syndrome (SBS)",
  "Shingles (Herpes Zoster)",
  "Short Bowel Syndrome (SB, Short Gut)",
  "Shoulder Replacement",
  "Sickle Cell Disease (SCD)",
  "Sickle Cell Disease (SCD): Sickle Cell Trait",
  "Sinusitis",
  "Sjogren's Syndrome (Sj, SS)",
  "Skin Cancer",
  "Skin Cancer- Melanoma",
  "Sleep Apnea (OSA)",
  "Sleep Disorder",
  "Small for Gestational Age SGA)",
  "Small Intestinal Bacterial Overgrowth (SIBO)",
  "Smith-Lemli-Opitz Syndrome (SLOS)",
  "Sotos Syndrome",
  "Spasmodic Dysphonia",
  "Spastic Diplegia",
  "Spasticity",
  "Speech Impairment",
  "Spherocytosis",
  "Sphincter of Oddi Dysfunction",
  "Spina Bifida (SB)",
  "Spinal Cancer",
  "Spinal Cord",
  "Spinal Muscular Atrophy (SMA)",
  "Sprengel Deformity",
  "Squamous Cell Carcinoma (SCC)",
  "Stickler Syndrome",
  "Stiff Person Syndrome (SPS)",
  "Stomach Cancer",
  "Stomach Ulcer",
  "Stroke",
  "Subcortical Band Heterotopia",
  "Subglottic Stenosis (SGS)",
  "Superior Mesenteric Artery Syndrome (SMA) (Wilkie's Syndrome)",
  "Syncope",
  "Synesthesia",
  "Syringomyelia",
  "Tardive Dyskinesia (TD)",
  "Tarlov Cysts",
  "Tay Sachs (TSD)",
  "Temporomandibular joint (TMJ)",
  "Tendonitis",
  "Testicular Cancer",
  "Thalassemia",
  "Thoracic Outlet Syndrome (TOS)",
  "Throat Cancer",
  "Thrombocytopenia (TCP)",
  "Thrombotic Thrombocytopenic Purpura (TTP)",
  "Thyroid Cancer",
  "Thyroid Eye Disease (TED)",
  "Thyroid Nodule",
  "Thyroidectomy",
  "Tics",
  "Tongue Cancer",
  "Tooth Agenesis (Anodontia)",
  "Torticollis (Wry Neck)",
  "Tourette Syndrome (TS)",
  "Townes-Brocks Syndrome",
  "Toxic Synovitis",
  "Tracheobronchomalacia (TBM)",
  "Tracheomalacia",
  "Transient Global Amnesia (TGA)",
  "Traumatic Brain Injury (TBI)",
  "Treacher Collins syndrome (TCS)",
  "Trichotillomania (TTM)",
  "Trigger Finger (Stenosing Tenosynovitis)",
  "Trisomy 13 (Patau Syndrome)",
  "Trisomy 18 (Edwards Syndrome)",
  "Trisomy X (XXX)",
  "Tuberculosis",
  "Ulcerative Colitis (UC)",
  "Undifferentiated Connective Tissue Disease (UCTD)",
  "Urea Cycle Disorder (UCD)",
  "Urethral Prolapse",
  "Urinary Tract Infection (UTI)",
  "Urticaria (Hives)",
  "Usher Syndrome",
  "Uterine Cancer",
  "Uterine Fibroids",
  "Uterine Leiomyosarcoma",
  "Uterine Polyps",
  "Uveitis",
  "VACTERL Syndrome",
  "Vaginismus",
  "Valosin Containing Protein (VCP)",
  "Varicose Veins",
  "Vasculitis",
  "Velopharyngeal Insufficiency (VPI)",
  "Vertebral Artery Dissection (VAD)",
  "Vertigo",
  "Vision Impairment",
  "Vitamin B12 Deficiency",
  "Vitamin D Deficiency",
  "Vitiligo",
  "Vocal Cord Dysfunction (VCD)",
  "Von Hippel-Lindau Syndrome",
  "Von Willebrand Disease (VWD)",
  "Vulvar Cancer",
  "Vulvodynia",
  "Waldenstrom's Macroglobulinemia (W, Lymphoplasmacytic Lymphoma)",
];

export const currency = [
  { name: "United States", value: "$", code: "USD", locale: "en-US", rate: 1 },
  { name: "United Kingdom", value: "£", code: "GBP", locale: "en-GB", rate: 0.784449 },
  { name: "Australia", value: "$", code: "AUD", locale: "en-AU", rate: 1.492084 },
  { name: "New Zealand", value: "$", code: "NZD", locale: "en-NZ", rate: 1.610206 },
  { name: "Canada", value: "$", code: "CAD", locale: "en-CA", rate: 1.341814 },
  { name: "India", value: "₹", code: "INR", locale: "en-IN", rate: 83.336089 }
];