import { QuestionDataType } from "../interfaceTypes";
import { dieses } from "./faqConstants";

export const QuestionData: Array<QuestionDataType> = [
  {
    "code": "P3Q3",
    "text": "What is your ethnicity?",
    "options": [
      "White",
      "Black or African",
      "American Indian or Alaska Native",
      "Asian",
      "Native Hawaiian and Other Pacific Islander",
      "Latino/Hispanic",
      "Other",
    ],
    "textLinkedWithOther": "Other",
    "type": ["RADIO", "TEXT"],
  },
  {
    "code": "P3Q4",
    "text": "What is your marital status?",
    "options": [
      "Never Married",
      "Married",
      "Separated",
      "Divorced",
      "Widowed",
    ],
    "type": ["RADIO"],
  },
  {
    "code": "P4Q5",
    "text": "What is your religious affiliation?",
    "options": [
      "Catholic (incl. Roman Catholic, Anglican, Orthodox, Baptist, Lutheran)",
      "Muslim",
      "Jewish",
      "Hindu",
      "Buddhist",
      "Sikh",
      "Agnostic (not sure if there is a God)",
      "Atheist (do not believe in god)",
      "Nothing in particular",
      "Prefer not to Answer",
      "Christian",
      "Other",
    ],
    "textLinkedWithOther": "Other",
    "type": ["RADIO", "TEXT"],
  },
  {
    "code": "P4Q1",
    "text": "Do you personally own any of the following?",
    "options": [
      "Dog",
      "Cat",
      "Credit Card",
      "House (own outright or with a mortgage)",
      "Car (own outright, with a loan, or through a lease)",
      "An Android mobile phone",
      "An Apple mobile phone",
      "None of the above",
    ],
    "type": ["CHECKBOX"],
  },
  {
    "code": "P4Q2",
    "text": "What is your current employment status?",
    "options": [
      "Employed Full-Time (35 Hours or more each week)",
      "Part-Time (34 Hours or less search week)",
      "Self-Employed",
      "Retired",
      "Student",
      "Unemployed",
      "Homemaker",
    ],
    "type": ["RADIO"],
  },
  {
    "code": "P4Q3",
    "text": "What industry do you work in? Please enter three characters to search for your industry.",
    "options": [
      "Advertising",
      "Aerospace/Defense",
      "Agriculture/Forestry/Fishing",
      "Architecture",
      "Automotive",
      "Business Services",
      "Chemicals",
      "Computer/Electronic Equipment",
      "Computer Related Products or Services",
      "Consulting",
      "Consumer Products",
      "Education",
      "Engineering",
      "Entertainment/Recreation",
      "Finance/Banking",
      "Food/Beverage/Restaurants",
      "Government(Local, State, Federal)",
      "Healthcare/Medical",
      "Hotel/Hospitality",
      "Insurance",
      "Internet Services",
      "Law Enforcement/Fire Services",
      "Legal",
      "Life Sciences",
      "Machinery/Equipment",
      "Manufacturing/Production",
      "Marketing services",
      "Market Research",
      "Media/Marketing/Advertising",
      "Medical Devices",
      "Military",
      "Mining",
      "Non-profit or Religious Organization",
      "Paper Products",
      "Petroleum/Petrochemicals",
      "Pharma/Biotech",
      "Printing/Publishing",
      "Printing/Publishing of computer industry information",
      "Real Estate",
      "Retail",
      "Technology",
      "Telecommunications",
      "Textiles/Apparel",
      "Trade Services",
      "Transportation",
      "Travel",
      "Trucking/Warehousing",
      "Utilities",
      "Value Added Reseller(VAR)/Systems Integrator",
      "Wholesale",
      "Other"
    ],
    "type": ["SELECT", "TEXT"],
    "textLinkedWithOther": "Other",
    "linked": {
      "questionCode": "P4Q2",
      "options": ["Employed Full-Time (35 Hours or more each week)", "Part-Time (34 Hours or less search week)", "Self-Employed",]
    }
  },
  {
    "code": "P4Q4",
    "text": "What best describes your current job title? Please enter three characters to search, if you can't find your title please select other.",
    "options": [
      "Account Executive/Senior Account Executive",
      "Account Manager/ Account Director",
      "Administrative Assistant / Executive Assistant",
      "Analyst / Senior Analyst",
      "Assistant Manager / Assistant Director",
      "Associate / Senior Associate",
      "Business Development Director",
      "Certified Public Accountant",
      "Chairman / Board Member",
      "Chemist / Scientist",
      "Chief Executive Officer",
      "Chief Financial Officer",
      "Chief Information Officer",
      "Chief Operations Officer",
      "Chief Technology Officer",
      "Computer Analyst",
      "Customer Service Representative",
      "Database Administrator",
      "Dentist / Dental Hygienenist",
      "Director / Department Head",
      "Executive Officer",
      "Executive VP / Senior VP",
      "Financial Advisor",
      "General Manager",
      "Graphic Designer",
      "Human Resources Director",
      "IT Consultant",
      "IT Manager",
      "Manager / Senior Manager",
      "Marketing Manager / Director",
      "Military / Government",
      "MIS Director",
      "Occupational / Physical Therapist",
      "Opticians / Optomestrist",
      "Owner / Proprietor / Principal",
      "Physician / Doctor",
      "Planner / Scheduler",
      "Police Officer / Fireman",
      "Product Manager",
      "Project Manager",
      "Publisher / Producer",
      "Secretary / Treasurer",
      "Server Architect",
      "Social Worker",
      "Software Developer",
      "Speech Pathologist/Audiologist",
      "Staffing / Hiring",
      "Systems Administrator",
      "Technicians / Technician Specialists",
      "Tradesman / Trade Specialist",
      "Vice President / Assistant VP",
      "Web Developer"
    ],
    "type": ["SELECT"],
    "linked": {
      "questionCode": "P4Q2",
      "options": ["Employed Full-Time (35 Hours or more each week)", "Part-Time (34 Hours or less search week)", "Self-Employed",]
    }
  },
  {
    "code": "P5Q5",
    "text": "What department do you work in?",
    "options": [
      "Account/Finance",
      "Administration",
      "Clerical",
      "Communications/PR",
      "Creative Services",
      "Customer Services",
      "Design/Engineering",
      "Executive/Owner",
      "General Management",
      "Human Resource",
      "Information Technology",
      "Infrastructure (Non-IT)",
      "Interior Design",
      "Janitorial",
      "Legal",
      "Manufacturing",
      "Marketing/Advertising",
      "Operations/Production",
      "Planing",
      "Project Management",
      "Quality Control",
      "Research and Development",
      "Sales/Business Development",
      "Security",
      "Supply Chain/Demand Management",
      "Warehouse/Transportation/Shipping",
      "Other",
    ],
    "textLinkedWithOther": "Other",
    "type": ["RADIO", "TEXT"],
    "linked": {
      "questionCode": "P4Q2",
      "options": ["Employed Full-Time (35 Hours or more each week)", "Part-Time (34 Hours or less search week)", "Self-Employed",]
    }
  },
  {
    "code": "P601",
    "text": "What is your level of education?",
    "options": [
      "Some high school, no diploma",
      "High school graduate, diploma or the equivalent (for example: GED)",
      "Some college credit, no degree",
      "Trade/technical/vocational training",
      "Associate degree",
      "Bachelor's degree",
      "Master's degree",
      "Professional degree",
      "Doctorate degree",
    ],
    "type": ["RADIO"],
  },
  {
    "code": "P602",
    "text": "What is your household income?",
    "options": [
      "Less than $25,000",
      "$25,000 - $50,000",
      "$50,000 - $100,000",
      "$100,000 - $200,000",
      "More than $200,000",
    ],
    "type": ["RADIO"],
  },
  {
    "code": "P603",
    "text": "Do you suffer from any chronic medical conditions? Enter three characters to search for your condition. Please choose none if this doesn't apply to you.",
    "options": [...dieses, "None"],
    "type": ["MULTISELECT"],
  },
  {
    "code": "P604. ",
    "text": `Are you a caregiver for a loved one with a chronic medical condition? Enter three characters to search for the condition of your loved one. Please choose none if this doesn't apply to you.`,
    "options": [...dieses, "None"],
    "type": ["MULTISELECT"],
  },
  // { "code": "SUS", "text": "SUS.", "type": [], "options": ["TEXT"], },
  {
    "code": "P6Q5",
    "text": "What type of diabetes do you have?",
    "options": [
      "Type | Diabetes",
      "Type || Diabetes"],
    "type": ["SELECT"],
    "linked": {
      "questionCode": "P603",
      "options": ["Diabetes (DM)"]
    }
  },
  {
    "code": "P6Q6",
    "text": "What kind of multiple sclerosis do you have?",
    "options": [
      "Multiple Sclerosis (MS): Clinically Isolated Syndrome (CIS)",
      "Multiple Sclerosis (MS): Primary Progressive  MS (PPMS)",
      "Multiple Sclerosis (MS): Progressive Relapsing MS (PRMS)",
      "Multiple Sclerosis (MS): Relapsing Remitting MS (RRMS)",
      "Multiple Sclerosis (MS): Secondary Progressive MS (SPMS)",
      "None or I don't know",
      "Other: Please specify, if nothing is listed or leave empty if you don't know.",
    ],
    "textLinkedWithOther": "Other: Please specify, if nothing is listed or leave empty if you don't know.",
    "type": ["SELECT", "TEXT"],
    "linked": {
      "questionCode": "P603",
      "options": ["Multiple Sclerosis (MS)"]
    }
  },
  {
    "code": "P6Q7",
    "text": "Please let us know your survey and research preferences",
    "options": [
      "Patient & Caregiver Surveys & Interviews",
      "Online Interviews",
      "Business Surveys & Interviews",
      "Consumer Surveys"
    ],
    "type": ["CHECKBOX"],
  },
];
