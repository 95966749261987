// packages block
import { FC, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, TextField } from "@mui/material";
// styles, constants, utils and interfaces block
import { PASSWORD, TEXT } from "../../constants";
import { CustomControlProps, PasswordType } from "../../interfaceTypes";
import ShowPassword from "../showPassword";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const CommonController: FC<CustomControlProps> = ({ controllerName, controllerLabel, fieldType, variant, isPassword, isDisabled, isMultiLine, error, country, setDate, date, dateErrorMessage }): JSX.Element => {
  const { control } = useFormContext();
  const [passwordType, setPasswordType] = useState<PasswordType>(PASSWORD);
  const [dateError, setDateError] = useState<any>()
  const [dateChanged, setDateChanged] = useState<any>()
  const handleClickShowPassword = () => {
    if (passwordType === PASSWORD) {
      setPasswordType(TEXT);
    } else {
      setPasswordType(PASSWORD);
    }
  };
  return (
    <Box>
      {fieldType === "Date" ? <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} >
            <DatePicker
              className="fullWidth"
              label={controllerLabel} format={country === "United Kingdom" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
              onChange={(e: any) => {
                setDateChanged(true)
                if (setDate) {
                  setDate(e)
                }
              }}
              slotProps={{
                textField: { helperText: dateError ? "Please enter a valid date" : (dateChanged && !date) || dateErrorMessage ? "DOB is required!" : "" },
              }}
              onError={(newError) => setDateError(newError)}
              // onViewChange={() => setDateChanged(true)}
              onOpen={() => setDateChanged(true)}
            />
          </DemoContainer>
        </LocalizationProvider>
        {/* {
          <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled " >
            {dateError ? "Please enter a valid date" : (dateChanged && !date) ? "Date of birth is required" : ""}
          </p>
        } */}
      </>
        : <Controller
          rules={{
            required: error ? error : true,
          }}
          name={controllerName}

          control={control}
          render={({ field, fieldState: { error: { message } = {} } }) => {
            return (
              <TextField
                type={fieldType === "password" ? passwordType : fieldType}
                variant={variant}
                sx={{ m: "8px 0" }}
                error={!!message}
                placeholder={controllerLabel}
                label={controllerLabel}
                fullWidth
                {...field}
                disabled={isDisabled}
                minRows={isMultiLine ? 3 : undefined}
                maxRows={isMultiLine ? 3 : undefined}
                multiline={isMultiLine}
                helperText={message && message}
                InputLabelProps={{
                  shrink: fieldType === "Date" ? true : undefined
                }}
                InputProps={isPassword ? {
                  endAdornment: <ShowPassword
                    isPassword={isPassword}
                    passwordType={passwordType}
                    handleShowPassword={handleClickShowPassword}
                  />,
                } : undefined}

              />
            )
          }}
        />
      }

    </Box >
  );
};

export default CommonController;
